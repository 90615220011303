/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useRef, useEffect } from 'react'

import { useRequest } from '../../request'
import { Auth } from '../..'

const ProOnBoardingContext = createContext({
    step: Number,
    handleEndFlow: (stepNumber) => {},
    saveAddPrestations: () => {},
})

export function ProOnBoardingContextProvider({ children }) {
    const { token, id, type, isLogged } = Auth.useAuth()
    const { handleRequest } = useRequest()

    const step = useRef(Number(localStorage.getItem('step')) || 0)
    const showOnboarding = useRef(Boolean(localStorage.getItem('showOnboarding')) || true)
    const completeProfile = useRef(Boolean(localStorage.getItem('completeProfile')) || false)
    const addPrestations = useRef(Boolean(localStorage.getItem('addPrestations')) || false)
    const paymentMode = useRef(Boolean(localStorage.getItem('paymentMode')) || false)
    const activeProfile = useRef(Boolean(localStorage.getItem('activeProfile')) || false)

    // Fonctions pour mettre à jour les références et localStorage
    const setStep = (value) => {
        step.current = value
        localStorage.setItem('step', value)
    }

    const setShowOnboarding = (value) => {
        showOnboarding.current = value
        localStorage.setItem('showOnboarding', value)
    }

    const setCompleteProfile = (value) => {
        completeProfile.current = value
        localStorage.setItem('completeProfile', value)
    }

    const setAddPrestations = (value) => {
        addPrestations.current = value
        localStorage.setItem('addPrestations', value)
    }

    const setPaymentMode = (value) => {
        paymentMode.current = value
        localStorage.setItem('paymentMode', value)
    }

    const setActiveProfile = (value) => {
        activeProfile.current = value
        localStorage.setItem('activeProfile', value)
    }

    // Fonctions pour sauvegarder les étapes et passer à l'étape suivante
    const saveCompleteProfile = async () => {
        setCompleteProfile(true)
        await handleRequest('put', 'onboarding/completeProfile', { completeProfile: true }, token)

        setStep(step.current + 1)
    }

    const saveAddPrestations = async () => {
        setAddPrestations(true)
        await handleRequest('put', 'onboarding/addPrestations', { addPrestations: true }, token)

        setStep(step.current + 1)
    }

    const savePaymentMode = async () => {
        setPaymentMode(true)
        await handleRequest('put', 'onboarding/paymentMode', { paymentMode: true }, token)

        setStep(step.current + 1)
    }

    const saveActiveProfile = async () => {
        setActiveProfile(true)
        await handleRequest('put', 'onboarding/activeProfile', { activeProfile: true }, token)

        setStep(step.current + 1)
        window.location.reload()
    }

    // repasse les valeurs a false en cas de retour et décrémenter les steps
    const unSaveCompleteProfile = async () => {
        setCompleteProfile(true)
        await handleRequest('put', 'onboarding/completeProfile', { completeProfile: false }, token)

        setStep(0)
    }

    const unSaveAddPrestations = async () => {
        setAddPrestations(true)
        await handleRequest('put', 'onboarding/addPrestations', { addPrestations: false }, token)

        setStep(step.current - 1)
    }

    const unSavePaymentMode = async () => {
        setPaymentMode(true)
        await handleRequest('put', 'onboarding/paymentMode', { paymentMode: false }, token)

        setStep(step.current - 1)
    }

    const unSaveActiveProfile = async () => {
        setActiveProfile(true)
        await handleRequest('put', 'onboarding/activeProfile', { activeProfile: false }, token)

        setStep(step.current - 1)
    }

    // Switch pour gérer la fin du flow en fonction de l'étape
    const handleEndFlow = (stepNumber) => {
        switch (stepNumber) {
            case 0:
                saveCompleteProfile()
                break
            case 1:
                saveAddPrestations()
                break
            case 2:
                savePaymentMode()
                break
            case 3:
                saveActiveProfile()
                break
        }
    }

    const handleGoBack = (stepNumber) => {
        switch (stepNumber) {
            case 0:
                unSaveCompleteProfile()
                break
            case 1:
                unSaveAddPrestations()
                break
            case 2:
                unSavePaymentMode()
                break
            case 3:
                unSaveActiveProfile()
                break
        }
    }

    const initiateState = (completeProfile, addPrestations, paymentMode, activeProfile) => {
        setCompleteProfile(completeProfile)
        setAddPrestations(addPrestations)
        setPaymentMode(paymentMode)
        setActiveProfile(activeProfile)

        let step
        if (activeProfile) {
            step = 4
            setShowOnboarding(false)
        } else {
            setShowOnboarding(true)
            step = 3
            if (!paymentMode) {
                step = 2
                if (!addPrestations) {
                    step = 1
                    if (!completeProfile) {
                        step = 0
                    }
                }
            }
        }
        setStep(step)
    }

    useEffect(() => {
        if (isLogged() && type === 'coiffeur') {
            const fetchOnboardingData = async () => {
                const response = await handleRequest('get', 'onboarding', null, token)

                const completeProfile = response?.data?.onboarding?.completeProfile
                const addPrestations = response?.data?.onboarding?.addPrestations
                const paymentMode = response?.data?.onboarding?.paymentMode
                const activeProfile = response?.data?.onboarding?.activeProfile

                initiateState(completeProfile, addPrestations, paymentMode, activeProfile)
            }

            fetchOnboardingData()
        }
    }, [token, id])

    return (
        <ProOnBoardingContext.Provider
            value={{
                step: step.current,
                showOnboarding: showOnboarding.current,
                handleEndFlow: handleEndFlow,
                saveAddPrestations: saveAddPrestations,
                handleGoBack: handleGoBack,
            }}
        >
            {children}
        </ProOnBoardingContext.Provider>
    )
}

export const useProOnBoarding = () => useContext(ProOnBoardingContext)
