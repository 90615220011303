import React, { useState } from 'react'
import dayjs from 'dayjs'
import { FR } from 'country-flag-icons/react/3x2'

import { Button, Cards, Input, Text, Icon, Loader } from '../../atoms'
import { Modify, Return, Search } from '../../molecules'
import { Auth, ClientContext } from '../../../contexts'

import { useNavigate } from 'react-router-dom'
import { HiOutlineMail, HiOutlinePhone, HiOutlineLocationMarker } from 'react-icons/hi'

export const RDV = () => {
    const navigate = useNavigate()

    const { loading, setCurrentBooking, bookingsClient, setStep } = ClientContext.Reservation.useReservation()

    if (loading) {
        return (
            <div className="text-center">
                <Loader.Primary />
                <Text.h4 className="text-center" color="v2_purple">
                    Récupération de vos rendez-vous
                </Text.h4>
            </div>
        )
    } else {
        return (
            <div>
                {bookingsClient.length > 0 ? (
                    <>
                        <div className="py-4">
                            {bookingsClient
                                .filter((booking) => {
                                    if (
                                        booking.state === 'booked' ||
                                        booking.state === 'waiting' ||
                                        booking.state === 'cancel'
                                    ) {
                                        if (
                                            dayjs(booking.date)
                                                .add(parseInt(booking.from / 60), 'hour')
                                                .add(parseInt(booking.to % 60), 'minutes')
                                                .isAfter(dayjs())
                                        ) {
                                            return true
                                        }
                                    }
                                })
                                .map((booking, index) => (
                                    <Cards.ClientRDV
                                        key={index}
                                        price={booking.price}
                                        from={booking.from}
                                        prestation={booking._prestation}
                                        state={booking.state}
                                        date={booking.date}
                                        canceledBy={booking.canceledBy}
                                        onClick={() => {
                                            setStep('main')
                                            setCurrentBooking(booking)
                                        }}
                                        onComment={() => {
                                            setStep('comment')
                                            setCurrentBooking(booking)
                                        }}
                                        priceVariable={booking.priceVariable}
                                    />
                                ))}
                        </div>
                        <div>
                            {bookingsClient.filter((booking) => booking.state === 'done').length > 0 && (
                                <div className="py-2">
                                    <div className="flex items-center justify-center w-full pb-6">
                                        <Icon.Book className="text-black w-9 h-9" />
                                        <Text.small color={'black'} className="font-bold">
                                            Historique des réservations
                                        </Text.small>
                                    </div>
                                    {bookingsClient
                                        .filter((booking) => booking.state === 'done')
                                        .reverse()
                                        .map((booking, index) => (
                                            <Cards.ClientRDVDone
                                                key={index}
                                                price={booking.price}
                                                from={booking.from}
                                                prestation={booking._prestation}
                                                date={booking.date}
                                                onClick={() => {
                                                    setStep('main')
                                                    setCurrentBooking(booking)
                                                }}
                                                onComment={() => navigate(`comment/${booking._id}`)}
                                                priceVariable={booking.priceVariable}
                                            />
                                        ))}
                                </div>
                            )}
                        </div>
                    </>
                ) : (
                    <Text.normal className={'mt-10'} align={'center'}>
                        Vous n'avez aucun rendez-vous
                    </Text.normal>
                )}
            </div>
        )
    }
}

export const Coordonnees = () => {
    const { email, handleDelete, handleLogout, handleChangePassword } = Auth.useAuth()
    const { firstName, lastName, phoneNumber, address, handleSetAttribute, handleSaveAttribute } =
        ClientContext.Profil.useProfil()

    const [step, setStep] = useState('main')

    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [comfirmPassword, setComfirmPassword] = useState('')

    const updateProfile = async () => {
        if (step === 'password') {
            await handleChangePassword(oldPassword, newPassword, comfirmPassword)
            setOldPassword('')
            setNewPassword('')
            setComfirmPassword('')
            setStep('main')
        }
    }

    const deleteProfile = async () => {
        await handleDelete()
    }

    return (
        <div className="flex flex-col w-full px-6">
            {step === 'main' && (
                <>
                    <Modify.Primary onClick={() => setStep('edit')} />
                    <div className="flex flex-col py-6 space-y-4">
                        <div className="flex items-center">
                            <HiOutlineMail className="mr-2 text-v2_purple" size={30} />
                            <Text.normal>{email}</Text.normal>
                        </div>
                        <div className="flex items-center">
                            <HiOutlinePhone className="mr-2 text-v2_purple" size={30} />
                            <div className="flex items-center mx-2">
                                <FR className="w-6 mr-2" />
                                (+{phoneNumber.code})
                            </div>
                            <Text.normal>{phoneNumber.phone}</Text.normal>
                        </div>
                        <div className="flex items-center">
                            <HiOutlineLocationMarker className="mr-2 text-v2_purple" size={30} />
                            <Text.normal>{address.formatted}</Text.normal>
                        </div>
                        <div className="fixed left-0 self-center w-full px-5 bottom-24">
                            <Button.secondary title={'Déconnexion'} onClick={() => handleLogout()} />
                        </div>
                    </div>
                </>
            )}
            {step === 'edit' && (
                <>
                    <Return.Primary customText={'Enregistrer'} onClick={() => setStep('main')} />
                    <div className="flex flex-col py-6 space-y-4">
                        <div className="flex justify-between">
                            <Input.text
                                name="firstName"
                                value={firstName}
                                placeholder="Prénom"
                                onChange={(newValue) => handleSetAttribute('firstName', newValue)}
                                onBlur={(value) => {
                                    handleSaveAttribute('firstName', value)
                                }}
                            />
                            <Input.text
                                name="lastName"
                                value={lastName}
                                placeholder="Nom"
                                onChange={(newValue) => handleSetAttribute('lastName', newValue)}
                                onBlur={(value) => {
                                    handleSaveAttribute('lastName', value)
                                }}
                            />
                        </div>
                        <Input.email name="email" value={email} placeholder="Email" onChange={() => {}} disabled />
                        <Input.phoneNumber
                            name="phoneNumber"
                            value={phoneNumber.phone}
                            placeholder="Numéro de téléphone"
                            onChange={(newValue) =>
                                handleSetAttribute('phoneNumber', { ...phoneNumber, phone: newValue })
                            }
                            handleSave={(newValue) => {
                                handleSaveAttribute('phoneNumber', {
                                    ...phoneNumber,
                                    phone: newValue,
                                })
                            }}
                        />
                        <Input.text
                            name="address"
                            value={address.formatted}
                            placeholder="Adresse"
                            onFocus={() => setStep('address')}
                        />
                    </div>
                    <div className="flex flex-col space-y-4">
                        <Button.primary title={'Changer de mot de passe'} onClick={() => setStep('password')} />
                        <div onClick={() => deleteProfile()}>
                            <Text.normal className="text-center underline">Supprimer mon compte</Text.normal>
                        </div>
                    </div>
                </>
            )}
            {step === 'address' && (
                <Search.Address
                    launchResearch={async (formatted, lat, lon) => {
                        await handleSaveAttribute('address', {
                            formatted: formatted,
                            lat: lat,
                            lon: lon,
                        })
                        setStep('edit')
                    }}
                />
            )}
            {step === 'password' && (
                <>
                    <Return.Primary onClick={() => setStep('edit')} />
                    <div className="flex flex-col py-6 space-y-4">
                        <Input.password
                            placeholder="Actuel mot de passe"
                            name="oldPassword"
                            value={oldPassword}
                            onChange={(value) => setOldPassword(value)}
                        />
                        <Input.password
                            placeholder="Nouveau mot de passe"
                            name="newPassword"
                            value={newPassword}
                            onChange={(value) => setNewPassword(value)}
                        />
                        <Input.password
                            placeholder="Comfirmer nouveau mot de passe"
                            name="comfirmPassword"
                            value={comfirmPassword}
                            onChange={(value) => setComfirmPassword(value)}
                        />
                        <div className="flex flex-col space-y-4">
                            <Button.primary title={'Enregistrer'} onClick={() => updateProfile()} />
                            <Button.secondary title={'Changer de mot de passe'} onClick={() => setStep('edit')} />
                            <div onClick={() => deleteProfile()}>
                                <Text.normal className="text-center underline">Supprimer mon compte</Text.normal>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}
