import React, { useEffect, useState } from 'react'
import { Input, Text } from '.'

import { CirclePlus, ArrowRight, ChevronUp, ChevronDown, Info, Trash2, Check } from 'lucide-react'

import ppBoy from '../../assets/avatar/Boy.svg'
import ppGirl from '../../assets/avatar/Girl.svg'
import defaultBanner from '../../assets/banner/defaultBanner.png'

import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { MessageCircle } from 'lucide-react'
import { Icon } from '.'

import { formatSecondsToHHhmm } from '../../utils'
import { ClientContext, ProContext } from '../../contexts'
import { Images } from '../organisms'

export const Primary = ({ title, text, textNavigation, onClick, onNavigate }) => {
    return (
        <div className="bg-[#fff] rounded-3xl pt-[34px] pb-[28px] px-[12px]">
            <div>
                <p className={'font-bold text-[24px] leading-[30px] text-center'}>{title}</p>
            </div>
            <div
                className="mt-[22px]"
                onClick={() => {
                    onNavigate ? onNavigate() : {}
                }}
            >
                <Input.search placeholder={'Entrez votre adresse'} onSearch={() => {}} />
            </div>
            <p className="text-[20px] leading-[20px] mt-[29px] text-center">
                {text}{' '}
                <span onClick={() => onClick()} className={'font-bold underline text-v2_purple'}>
                    {textNavigation}
                </span>
            </p>
        </div>
    )
}

export const ProCategorie = ({ title }) => {
    return (
        <div className="px-4 py-2 rounded-full border border-[#72517E] inline-block bg-white">
            <Text.small align={'center'} className={'uppercase'}>
                {title}
            </Text.small>
        </div>
    )
}

export const ProSpecialities = ({ title }) => {
    return (
        <div className="inline-block px-6 py-2 overflow-hidden truncate rounded-full w-fit bg-v2_lightpurple">
            <Text.normal className="text-black">{title}</Text.normal>
        </div>
    )
}

export const ProProfil = ({ name, experience, onClick, ppCoiffeur, gender }) => {
    return (
        <div className="bg-[#fff] py-[15px] rounded-3xl flex flex-row shadow-lg space-x-[24px] px-[34px]">
            <div className="width">
                <div
                    style={{
                        backgroundImage: `url(${ppCoiffeur ? ppCoiffeur : gender === 'male' ? ppBoy : ppGirl})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center center',
                    }}
                    className="size-[70px] rounded-full shadow-profil"
                ></div>
            </div>
            <div
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                }}
                className={`${onClick ? 'pt-[16px]' : ''}`}
            >
                <Text.h5 color={'[#000]'} className={'font-bold'}>
                    {name}
                </Text.h5>
                <p className="text-[#000] text-[16px] tracking-tight leading-8">{experience} années d'expériences</p>
                {onClick ? (
                    <div className="flex flex-row items-center mt-2 cursor-pointer">
                        <div>
                            <Text.normal className={'font-bold underline text-v2_purple'}>Prestations</Text.normal>
                        </div>
                        <div>
                            <ArrowRight color="#72517E" size={24} />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export const ClientProfil = () => {
    const { firstName, lastName, avatar, handleSaveAttribute } = ClientContext.Profil.useProfil()

    const [changeAvatar, setChangeAvatar] = useState(false)
    const [loading, setLoading] = useState(false)

    return (
        <div className="bg-[#fff] mx-4 p-4 rounded-3xl flex flex-row shadow-2md">
            <Images.Upload
                title="Changer de photo de profil"
                setIsOpen={() => setChangeAvatar(false)}
                isOpen={changeAvatar}
                afterUpload={async (value) => {
                    await handleSaveAttribute('avatar', value)
                    setChangeAvatar(false)
                    setLoading(false)
                }}
                setLoading={(value) => setLoading(value)}
                loading={loading}
            />
            <div className="mr-4">
                <div className="relative">
                    <div className="overflow-hidden rounded-full size-24">
                        {avatar ? (
                            <img src={avatar?.thumbnailUrl} alt="Photo de profil du Client" />
                        ) : (
                            <img src={ppBoy} alt="Photo de profil par défault du Client" />
                        )}
                    </div>
                    <CirclePlus
                        onClick={() => setChangeAvatar(true)}
                        className="absolute bottom-0 right-0 bg-white rounded-full"
                        color="#000"
                        size={20}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-center">
                <Text.h5 className={'font-bold'} color={'black'}>
                    {firstName}
                </Text.h5>
                <Text.h5 className={'font-bold'} color={'black'}>
                    {lastName}
                </Text.h5>
            </div>
        </div>
    )
}

export const ClientProfilRdv = ({ clientSelected }) => {
    const { avatar } = ClientContext.Profil.useProfil()
    const { deleteClient } = ProContext.Directory.useDirectory()

    return (
        <div className="flex justify-between bg-[#fff] mx-4 p-4 rounded-3xl flex-row shadow-basic">
            <div className="flex flex-row">
                <div className="mr-4">
                    <div className="relative">
                        <div className="overflow-hidden rounded-full size-[80px]">
                            {avatar ? (
                                <img src={avatar?.thumbnailUrl} alt="Photo de profil du Client" />
                            ) : clientSelected?._user?._profil?.avatar ? (
                                <img
                                    src={clientSelected?._user?._profil?.avatar?.thumbnailUrl}
                                    alt="Photo de profil du Client"
                                />
                            ) : (
                                <img src={ppBoy} alt="Photo de profil par défault du Client" />
                            )}
                            {clientSelected._user?.email &&
                                clientSelected._user?.email?.indexOf('@') !== -1 &&
                                clientSelected?._user?._profil?.address?.formatted &&
                                clientSelected?._user?._profil?.phoneNumber?.phone && (
                                    <div className="absolute right-0 flex items-center justify-center w-5 h-5 rounded-full bottom-1 bg-v2_green">
                                        <Check className="w-4 h-4 text-white" />
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center">
                    <p className="font-semibold text-[20px]">
                        {clientSelected?._user?._profil?.firstName} {clientSelected?._user?._profil?.lastName}
                    </p>
                </div>
            </div>
            <div className="flex w-[24px] items-start">
                <Trash2 size={24} onClick={() => deleteClient(clientSelected?._id)} />
            </div>
        </div>
    )
}

export const ClientProfileCard = ({ avatar, name }) => {
    return (
        <div className="bg-[#fff] py-4 rounded-3xl flex flex-row shadow-2md">
            <div className="mr-4">
                <div className="relative">
                    <div className="overflow-hidden rounded-full size-[55px]">
                        {avatar ? (
                            <img src={avatar?.thumbnailUrl} alt="Photo de profil du Client" />
                        ) : (
                            <img src={ppBoy} alt="Photo de profil par défault du Client" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center">
                <p className="font-medium text-[16px]">{name}</p>
            </div>
        </div>
    )
}

export const ClientDirectory = ({ avatar }) => {
    return (
        <div className="bg-[#fff] rounded-3xl flex flex-row shadow-2md">
            <div className="relative">
                <div className="overflow-hidden rounded-full size-[55px]">
                    {avatar ? (
                        <img src={avatar?.thumbnailUrl} alt="Photo de profil du Client" />
                    ) : (
                        <img src={ppBoy} alt="Photo de profil par défault du Client" />
                    )}
                </div>
            </div>
        </div>
    )
}

export const ClientRDV = ({ state, prestation, date, from, price, canceledBy, onClick, onComment, priceVariable }) => {
    const State = () => {
        switch (state) {
            case 'waiting':
                return 'RDV en attente de confirmation'
            case 'cancel':
                if (canceledBy === 'client') return 'RDV Annulé par vous'
                else return 'RDV Annulé par le professionnel'
            case 'booked':
                return 'RDV confirmé'
            case 'done':
                return 'RDV passé'
            default:
                return ''
        }
    }

    return (
        <div className="bg-[#fff] mx-6 mb-6 px-6 pt-6 rounded-3xl flex flex-col shadow-basic">
            <div onClick={() => onClick()} className="cursor-pointer">
                <div className="flex w-full pb-4">
                    <div className="flex w-10">
                        <div
                            className={`flex h-[14px] w-[14px] rounded-full ${
                                state === 'booked'
                                    ? 'bg-v2_green'
                                    : state === 'cancel'
                                    ? 'bg-v2_red'
                                    : state === 'waiting'
                                    ? 'bg-v2_yellow'
                                    : 'bg-v2_grey'
                            }`}
                        ></div>
                    </div>
                    <div className="flex flex-col w-full">
                        <Text.normal className="pb-2 font-bold">
                            <State />
                        </Text.normal>
                        <ul className="pl-4 text-xl leading-6 list-disc">
                            {prestation.map((item, index) => (
                                <li key={index} className={state === 'cancel' ? 'text-v2_grey' : ''}>
                                    <Text.normal>{item.name}</Text.normal>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="flex pb-2">
                    <Text.small color={'black'}>
                        {dayjs(date).locale('fr').format('ddd D MMMM YYYY')} à {formatSecondsToHHhmm(from)}
                    </Text.small>
                </div>
                <div className="flex pb-2 border-v2_grey border-b-[1px]">
                    {state === 'booked' && (
                        <Text.normal className="pb-2">
                            Le coût total : {priceVariable ? 'À partir de ' : ''}
                            {price} €
                        </Text.normal>
                    )}
                </div>
            </div>
            <div className="flex items-center justify-center w-full h-16 cursor-pointer" onClick={() => onComment()}>
                <Text.small className={'mr-1'} color={'black'}>
                    Laisser un commentaire{' '}
                </Text.small>
                <MessageCircle color="black" size={20} />
            </div>
        </div>
    )
}

export const ClientRDVDone = ({ prestation, date, from, price, onClick, priceVariable }) => {
    return (
        <div>
            {prestation.map((i, index) => {
                return (
                    <div
                        key={index}
                        className="bg-[#fff] w-full mb-6 px-6 pt-6 flex flex-col shadow-basic cursor-pointer"
                        onClick={() => onClick()}
                    >
                        <div className="flex w-full pb-4">
                            <div className="flex items-center w-10">
                                <div className={`flex h-[14px] w-[14px] rounded-full bg-v2_grey`}></div>
                            </div>
                            <div className="flex flex-col w-full">
                                <ul className="pl-4 text-xl leading-6">
                                    <li>
                                        <Text.normal>
                                            {i.name} - {priceVariable ? 'À partir de ' : ''}
                                            {price} €
                                        </Text.normal>
                                    </li>
                                    <li>
                                        <Text.small color={'black'}>
                                            {dayjs(date).locale('fr').format('ddd D MMMM YYYY')} à{' '}
                                            {formatSecondsToHHhmm(from)}
                                        </Text.small>
                                    </li>
                                </ul>
                            </div>
                            <div className="flex items-center justify-center w-10">
                                <Info color="#000" size={30} />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export const SearchFilter = ({ categories, label, onClick, value }) => {
    return (
        <div className="bg-[#fff] p-4 rounded-3xl">
            <p className="text-[16px] font-semibold text-v2_purple mt-4">{label}</p>
            <div className="mt-[16px]">
                {categories?.map((categorie, index) => {
                    return (
                        <div key={index} className="flex flex-row mt-2">
                            <div className="mr-2">
                                <input
                                    type="checkbox"
                                    className="accent-[#72517E]"
                                    onClick={() => onClick(categorie.value)}
                                    checked={
                                        typeof value === 'object'
                                            ? value.indexOf(categorie.value) !== -1
                                            : value === categorie.value
                                    }
                                />
                            </div>
                            <div>
                                <p className="text-[16px] font-medium">{categorie.label}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export const Pro = ({ name, prestations, onClick, onFavorite, favoriState, cover, categories }) => {
    const [favori, setFavori] = useState(favoriState || false)
    const [seeMore, setSeeMore] = useState(false)

    const handleFavorite = () => {
        const value = !favori
        setFavori(value)
        onFavorite(value)
    }

    return (
        <div className="inline-block shadow-modal border-[0.5px] border-v2_purple rounded-[30px] overflow-hidden mx-auto w-[95%]">
            <div
                style={{
                    backgroundImage: `url(${cover ? cover : defaultBanner})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
                className="w-full h-[163px] relative top-0"
                onClick={() => onClick()}
            >
                <div className="absolute overflow-scroll top-[10px] left-[16px]">
                    {categories?.map((category) => (
                        <ProCategorie key={category} title={category} />
                    ))}
                </div>
            </div>
            <div className="bg-[#fff] flex flex-col rounded-b-[30px] w-full">
                <div className="w-[89%] mx-auto flex flex-col space-y-[10px] pt-[13px] pb-[10px]">
                    <div className="flex flex-row items-center justify-between w-full mx-auto">
                        <div onClick={() => onClick()}>
                            <Text.h5 className="font-medium" color={'#000'}>
                                {name}
                            </Text.h5>
                        </div>
                        <div className="z-10 cursor-pointer" onClick={() => handleFavorite()}>
                            {favori ? (
                                <Icon.Heart className="w-[32px] text-[#72517E]" />
                            ) : (
                                <Icon.Heart className="w-[32px] text-[#DFCEEA]" />
                            )}
                        </div>
                    </div>
                    {prestations && (
                        <div className="flex flex-col space-y-[4px] h-fit">
                            {!seeMore && prestations ? (
                                <div className="flex flex-row flex-nowrap space-x-[7px] overflow-scroll h-fit">
                                    {prestations.slice(0, 2).map((i, index) => {
                                        return (
                                            <div
                                                onClick={() => onClick()}
                                                key={index}
                                                className="bg-[#DFCDEA] h-fit px-[11px] rounded-full"
                                            >
                                                <p className="text-[12px] leading-8 font-medium text-nowrap">
                                                    {i.name}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            ) : (
                                <div className="flex flex-row flex-wrap space-x-[7px] overflow-scroll h-fit">
                                    {prestations.map((i, index) => {
                                        return (
                                            <div
                                                onClick={() => onClick()}
                                                key={index}
                                                className="bg-[#DFCDEA] h-fit px-[11px] rounded-full mb-[8px]"
                                            >
                                                <p className="text-[12px] text-nowrap leading-8 font-medium">
                                                    {i.name}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            <div className="flex flex-row items-center cursor-pointer h-fit">
                                <div className="mr-2" onClick={() => setSeeMore(!seeMore)}>
                                    {seeMore ? (
                                        <Text.small className={'font-semibold underline leading-8'}>
                                            Afficher moins
                                        </Text.small>
                                    ) : (
                                        <Text.small className={'font-semibold underline leading-8'}>
                                            Afficher plus
                                        </Text.small>
                                    )}
                                </div>
                                <div>
                                    {seeMore ? (
                                        <ChevronUp onClick={() => setSeeMore(!seeMore)} color="#72517E" size={24} />
                                    ) : (
                                        <ChevronDown onClick={() => setSeeMore(!seeMore)} color="#72517E" size={24} />
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="mx-auto border-t border-[#D0D0D0] w-[86%] pb-[10px] pt-[3px]" onClick={() => onClick()}>
                    <p className="text-black text-center uppercase text-[14px] leading-8 font-semibold tracking-tight">
                        Prochaine disponibilité
                    </p>
                    <p className="text-[#72517E] leading-8 font-normal tracking-tight text-center">Jeudi 30 Mai 2024</p>
                </div>
            </div>
        </div>
    )
}

export const Counter = ({ counter, increment, decrement }) => {
    const [incrementAnimation, setIncrementAnimation] = useState(false)
    const [decrementAnimation, setDecrementAnimation] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if (incrementAnimation) {
                setIncrementAnimation(false)
            }
            if (decrementAnimation) {
                setDecrementAnimation(false)
            }
        }, 500)
    }, [counter])

    return (
        <div
            className={`p-[11px] py-[8px] rounded-full border gap-[15px] transition-all items-center bg-v2_white border-[#72517E] ${
                counter === 0 ? 'flex px-[15px]' : 'grid grid-cols-3'
            } ${incrementAnimation ? 'background-fade' : ''} ${decrementAnimation ? 'background-fade-reverse' : ''}`}
        >
            <div
                onClick={() => {
                    setDecrementAnimation(true)
                    decrement()
                }}
                className={`font-medium text-[14px] leading-5 cursor-pointer ${
                    counter === 0 ? 'hidden' : 'text-[#000] block'
                }`}
            >
                <p>—</p>
            </div>
            <p className={`font-medium text-[16px] leading-5 text-[#000] ${counter === 0 ? 'hidden' : 'block'}`}>
                {counter}
            </p>
            <div
                onClick={() => {
                    setIncrementAnimation(true)
                    increment()
                }}
            >
                <p className="font-light text-[24px] leading-5 cursor-pointer text-[#000]">+</p>
            </div>
        </div>
    )
}

export const icons = ({ icon, title }) => {
    return (
        <div className="p-4 rounded-full inline-block bg-[#fff] w-[165px]">
            <div className="flex flex-row justify-center mb-1">
                <img src={icon} className="w-[53px]" />
            </div>
            <Text.small align={'center'} className={'uppercase'}>
                {title}
            </Text.small>
        </div>
    )
}

export const iconsLucide = ({ icon, title, selected, onClick }) => {
    return (
        <div
            className={`rounded-full inline-block ${
                selected
                    ? 'bg-v2_lightpurple border text-v2_purple'
                    : 'bg-[#C4C4C4] bg-opacity-50 border border-[#6c6c6c] text-[#6C6C6C]'
            } py-1 px-2 w-maw max-w-[94px]`}
            onClick={() => onClick()}
        >
            <div className="flex flex-row justify-center mb-1">{icon}</div>
            <p className={'text-[12px] font-medium leading-5 text-center'}>{title}</p>
        </div>
    )
}
