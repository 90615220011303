import React, { useEffect, useState, useRef } from 'react'
import { Button, Input, Text, Cards } from '../../atoms'
import { Address, Auth, ProContext } from '../../../contexts'

import { ArrowLeft, MapPin, Search } from 'lucide-react'

const Research = ({
    launchResearch,
    noHome,
    setRequestInProgress,
    instantLaunch,
    fromProfil,
    goBackToProfile,
    setAddressSearched,
    noAddressDefault,
}) => {
    const { type } = Auth.useAuth()
    const {
        selectedAddress,
        searchAddress,
        addressSuggestions,
        getLatLong,
        step,
        setStep,
        categories,
        setCategories,
        CATEGORIES,
    } = Address.useAddress()

    const [research, setResearch] = useState(false)
    const [searchValue, setSearchValue] = useState(selectedAddress)
    const inputRef = useRef(null)

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
        if (searchValue?.length > 0 && !noAddressDefault) {
            handleSearchAddress(searchValue)
        }
    }, [step])

    return (
        <>
            {!research ? (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                        <div
                            onClick={() => {
                                setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                                if (fromProfil) {
                                    goBackToProfile()
                                }
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            {!research ? <p>Adresse</p> : <p>Rechercher</p>}
                        </div>
                    </div>
                    <div className="shadow-modal px-[22px]">
                        <div className="flex flex-row items-center p-2">
                            <div className="w-full mr-2">
                                <input
                                    ref={inputRef}
                                    value={searchValue}
                                    placeholder={'Entrez votre adresse (N°, rue, ville...)'}
                                    className="w-full"
                                    type="text"
                                    onChange={(e) => {
                                        const value = e.target.value
                                        setSearchValue(value.toString())
                                        handleSearchAddress(value.toString())
                                    }}
                                />
                            </div>
                            <div onClick={() => setResearch(true)}>
                                <Search color="#72517E" size={24} />
                            </div>
                        </div>
                    </div>
                    {addressSuggestions && addressSuggestions.length > 0 && (
                        <div className="bg-white shadow-modal h-logged overflow-auto">
                            {addressSuggestions.map((i, index) => (
                                <div key={index}>
                                    {i && (
                                        <div>
                                            <div
                                                className="flex flex-row items-center w-full px-4 py-2 border-b-[0.5px] border-b-black"
                                                onClick={async (e) => {
                                                    setSearchValue(e.target.innerText)
                                                    setRequestInProgress(true)
                                                    const res = await getLatLong(i.autocompleteId, i.sessionId, i)
                                                    if (fromProfil) {
                                                        setAddressSearched(res)
                                                        goBackToProfile()
                                                    }

                                                    if (instantLaunch) launchResearch()
                                                    else setResearch(true)
                                                }}
                                            >
                                                <div className="mr-4">
                                                    <MapPin color="#000" size={24} />
                                                </div>
                                                <div>
                                                    <Text.h5 className="font-[500]" color={'#000'}>
                                                        {i.full_address}
                                                    </Text.h5>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ) : (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    {type !== 'coiffeur' && (
                        <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                            <div
                                onClick={() => {
                                    setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                                }}
                            >
                                <ArrowLeft size={24} color="#72517E" />
                            </div>
                            <div className="mr-auto ml-auto text-[20px] font-[500]">
                                {!research ? <p>Adresse</p> : <p>Rechercher</p>}
                            </div>
                        </div>
                    )}

                    <div className="shadow-modal p-[40px] rounded-[30px]">
                        <Input.Edit
                            placeholder={'Où (Adresse, ville...)'}
                            onSearch={() => setResearch(false)}
                            value={searchValue}
                        />
                        {type !== 'coiffeur' && (
                            <div className="mt-6">
                                <Cards.SearchFilter
                                    label={'Vous recherchez'}
                                    onClick={(value) => {
                                        let filterCopy = [...categories]
                                        let indexToDelete = filterCopy.indexOf(value)

                                        if (indexToDelete !== -1) {
                                            filterCopy.splice(indexToDelete, 1)
                                        } else {
                                            filterCopy.push(value)
                                        }

                                        setCategories(filterCopy)
                                    }}
                                    value={categories}
                                    categories={CATEGORIES}
                                />
                            </div>
                        )}
                    </div>

                    <div className="absolute left-0 right-0 w-full px-4 bottom-12">
                        <Button.primary
                            title={'Continuer'}
                            onClick={() => {
                                categories.length != 0 && launchResearch ? launchResearch() : {}
                            }}
                            disabled={categories.length === 0}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export const ResearchForPlaces = ({ launchResearch, noHome }) => {
    const { searchAddress, addressSuggestions, getLatLong, step, setStep, coordinates } = Address.useAddress()
    const { selected, setSelected } = ProContext.Place.usePlace()

    const [research, setResearch] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    useEffect(() => {
        if (selected.name) {
            setSearchValue(selected.address.formatted)
            setResearch(true)
        }
    }, [])

    return (
        <>
            {!research ? (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                        <div
                            onClick={() => {
                                setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            <p>Addresse du lieu</p>
                        </div>
                    </div>
                    <div className="shadow-modal px-[22px]">
                        <Input.searchAddress
                            value={searchValue}
                            placeholder={'Entrez votre adresse (N°, rue, ville...)'}
                            onChange={(value) => {
                                setSearchValue(value.toString())
                                handleSearchAddress(value.toString())
                            }}
                            onSearch={() => {
                                setResearch(true)
                            }}
                        />
                    </div>
                    {addressSuggestions && addressSuggestions.length > 0 ? (
                        <div className="bg-white shadow-modal h-logged overflow-auto">
                            {addressSuggestions.map((i, index) => {
                                return (
                                    <div key={index}>
                                        {i ? (
                                            <div>
                                                <div
                                                    className="flex flex-row items-center w-full px-4 py-2 border-b-[0.5px] border-b-black"
                                                    key={index}
                                                    onClick={async (e) => {
                                                        setSearchValue(e.target.innerText)
                                                        await getLatLong(i.autocompleteId, i.sessionId, i)

                                                        setSelected({
                                                            address: {
                                                                formatted: searchValue,
                                                                lat: coordinates.latitude,
                                                                lon: coordinates.longitude,
                                                            },
                                                        })
                                                        setResearch(true)
                                                    }}
                                                >
                                                    <div className="mr-4">
                                                        <MapPin color="#000" size={24} />
                                                    </div>
                                                    <div>
                                                        <Text.h5 className="font-[500]" color={'#000'}>
                                                            {i.full_address}
                                                        </Text.h5>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                )
                            })}
                        </div>
                    ) : null}
                </div>
            ) : (
                <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                    <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                        <div
                            onClick={() => {
                                setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                            }}
                        >
                            <ArrowLeft size={24} color="#72517E" />
                        </div>
                        <div className="mr-auto ml-auto text-[20px] font-[500]">
                            <p>Nom du lieu</p>
                        </div>
                    </div>
                    <div className="shadow-modal px-[13px] py-[22px] rounded-[30px]">
                        <Input.Edit
                            placeholder={'Où (Adresse, ville...)'}
                            onSearch={() => setResearch(false)}
                            value={searchValue}
                        />
                        <Input.text
                            placeholder={'Nom du lieu pour les clients'}
                            onChange={(value) => setSelected({ ...selected, name: value })}
                            value={selected.name}
                        />
                    </div>

                    <div className="absolute left-0 right-0 w-full px-4 bottom-12">
                        <Button.primary
                            title={'Continuer'}
                            onClick={() => {
                                selected?.name?.length < 2 ? null : launchResearch()
                            }}
                            disabled={selected?.name?.length < 2}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default Research
