import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Message } from '../atoms'
import { Navbar } from '../molecules'
import { Unlogged } from '../organisms'
import { Profesionnal } from '../../pages'

import { Auth, Address, Request, ProContext } from '../../contexts'
import { socket } from '../../api/api'

const UnLogged = () => {
    const { message } = Request.useRequest()
    const { isLogged, type, firstVisit } = Auth.useAuth()
    const { step } = Address.useAddress()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (isLogged() && location.pathname !== '/reservation') {
            if (type === 'coiffeur') {
                navigate('/pro')
            } else if (type === 'client') {
                navigate('/client')
            }
        }
    }, [isLogged, type])

    return (
        <div>
            {firstVisit ? (
                <Unlogged.OnBoarding />
            ) : (
                <div className="container relative text-black">
                    <Navbar.UnLogged />
                    <div
                        className={`px-2 py-4 overflow-x-hidden md:px-6 z-[99] h-unlogged overflow-y-auto ${
                            location.pathname === '/'
                                ? step === 'home'
                                    ? 'bg-background_light'
                                    : 'bg-white'
                                : 'bg-white'
                        }`}
                        id="scrollable-zone"
                    >
                        {message?.message && (
                            <>
                                {message.type === 'info' && <Message.Info message={message.message} />}
                                {message.type === 'warning' && <Message.Warning message={message.message} />}
                                {message.type === 'error' && <Message.Error message={message.message} />}
                                {message.type === 'success' && <Message.Success message={message.message} />}
                            </>
                        )}
                        <Outlet />
                    </div>
                </div>
            )}
        </div>
    )
}

const UnLoggedBooking = () => {
    const { message } = Request.useRequest()
    const navigate = useNavigate()

    useEffect(() => {
        const location = window.location.pathname
        if (!location.endsWith('details')) {
            navigate(`${location}/details`)
        }
    }, [])

    return (
        <div className="container relative text-black">
            <Navbar.UnLogged />
            <div className="px-2 py-4 overflow-x-hidden md:px-6 z-[99] h-unlogged overflow-y-auto" id="scrollable-zone">
                {message.message && (
                    <>
                        {message.type === 'info' && <Message.Info message={message.message} />}
                        {message.type === 'warning' && <Message.Warning message={message.message} />}
                        {message.type === 'error' && <Message.Error message={message.message} />}
                        {message.type === 'success' && <Message.Success message={message.message} />}
                    </>
                )}
                <Outlet />
            </div>
        </div>
    )
}

const Client = () => {
    const { message } = Request.useRequest()

    return (
        <div className="container relative text-black">
            <Navbar.LoggedTop />
            <div className="px-2 py-4 overflow-x-hidden overflow-y-auto md:px-6 h-logged" id="scrollable-zone">
                {message.message && (
                    <>
                        {message.type === 'info' && <Message.Info message={message.message} />}
                        {message.type === 'warning' && <Message.Warning message={message.message} />}
                        {message.type === 'error' && <Message.Error message={message.message} />}
                        {message.type === 'success' && <Message.Success message={message.message} />}
                    </>
                )}
                <Outlet />
            </div>
            <Navbar.Client />
        </div>
    )
}

const Pro = () => {
    const { id, token, isLogged } = Auth.useAuth()
    const { message } = Request.useRequest()
    const { step } = ProContext.OnBoarding.useProOnBoarding()
    const { getWaitings } = ProContext.Bookings.useBooking()

    useEffect(() => {
        if (isLogged()) {
            getWaitings()
            socket.emit('register-pro-device', id)
            socket.on('new_booking', (data) => {
                console.log('new booking', data)
                getWaitings()
            })
        }
    }, [id, token])

    return (
        <ProContext.Place.PlaceContextProvider>
            <ProContext.OnBoarding.ProOnBoardingContextProvider>
                <div className="text-black">
                    <Navbar.LoggedTop />
                    <div className="overflow-x-hidden overflow-y-auto h-logged" id="scrollable-zone">
                        {message.message && (
                            <>
                                {message.type === 'info' && <Message.Info message={message.message} />}
                                {message.type === 'warning' && <Message.Warning message={message.message} />}
                                {message.type === 'error' && <Message.Error message={message.message} />}
                                {message.type === 'success' && <Message.Success message={message.message} />}
                            </>
                        )}
                        {step < 4 ? <Profesionnal.Onboarding /> : <Outlet />}
                    </div>
                    <div className="fixed bottom-0 w-full">
                        <Navbar.Coiffeur />
                    </div>
                </div>
            </ProContext.OnBoarding.ProOnBoardingContextProvider>
        </ProContext.Place.PlaceContextProvider>
    )
}

export { UnLogged, UnLoggedBooking, Client, Pro }
