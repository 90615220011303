import React, { useEffect, useMemo, useState } from 'react'

import { Cards, Text } from '../../atoms'
import { ProContext } from '../../../contexts'

import { ChevronRight, ArrowLeft, Pencil } from 'lucide-react'
import dayjs from 'dayjs'

const ClientDirectory = () => {
    const { clients, settingClientSelected, getDirectory } = ProContext.Directory.useDirectory()

    const sortedClients = useMemo(() => {
        if (clients) {
            return clients.sort((a, b) => a._user?._profil?.firstName?.localeCompare(b._user?._profil?.firstName))
        }
    }, [clients])

    const groupedClients = useMemo(() => {
        if (sortedClients) {
            return sortedClients.reduce((acc, client) => {
                const firstName = client?._user?._profil?.firstName || ''
                const firstLetter = firstName.charAt(0).toUpperCase()
                if (!acc[firstLetter]) {
                    acc[firstLetter] = []
                }
                acc[firstLetter].push(client)
                return acc
            }, {})
        }
    }, [sortedClients])

    useEffect(() => {
        getDirectory()
    }, [])

    if (clients)
        return (
            <div className="px-[6px]">
                {Object.keys(groupedClients).length === 0 ? (
                    <p className="text-[16px] text-center">Aucun client</p>
                ) : (
                    Object.keys(groupedClients).map((letter) => (
                        <div key={letter}>
                            <div className="w-full bg-white shadow-md shadow-[#DFCDEA80] px-2 my-4">
                                <p className="font-bold text-[16px] text-v2_purple">{letter}</p>
                            </div>
                            {groupedClients[letter].length === 0 ? (
                                <p className="text-center text-gray-500">Aucun client pour cette lettre.</p>
                            ) : (
                                groupedClients[letter].map((client) => (
                                    <div
                                        className="flex flex-row items-center justify-between w-full mb-3 px-[15px]"
                                        key={client._id}
                                        onClick={() => {
                                            settingClientSelected(client)
                                        }}
                                    >
                                        <Cards.ClientDirectory avatar={client?._user?._profil?.avatar} />
                                        <div className="flex flex-col w-full pl-4">
                                            <p className="text-[16px] font-bold">
                                                {client?._user?._profil?.firstName} {client?._user?._profil?.lastName}
                                            </p>
                                            <p className="text-[12px] font-normal">
                                                {client?._user?._profil.address?.formatted}
                                            </p>
                                        </div>
                                        <ChevronRight size={25} color="#000" />
                                    </div>
                                ))
                            )}
                        </div>
                    ))
                )}
            </div>
        )
}

const Notes = () => {
    const { clientSelected, addNote, setAddNote, saveClientNotes } = ProContext.Directory.useDirectory()

    const [view, setView] = useState('technical')
    const [technical, setTechnical] = useState(clientSelected?.notes?.technical || '')
    const [other, setOther] = useState(clientSelected?.notes?.other || '')

    useEffect(() => {
        if (clientSelected?.notes) {
            setTechnical(clientSelected.notes.technical)
            setOther(clientSelected.notes.other)
        }
    }, [clientSelected])

    const [debounceTimeout, setDebounceTimeout] = useState(null)

    // Fonction qui sera appelée à chaque changement d'input
    const handleChange = (e) => {
        const notes = { technical: technical, other: other }
        let value = e.target.value
        let date = dayjs().toISOString()

        if (e.target.id === 'technical') {
            notes.technical = { value: value, date: date }
            setTechnical({ ...technical, ...notes.technical })
        } else {
            notes.other = { value: value, date: date }
            setOther({ ...other, ...notes.other })
        }

        if (debounceTimeout) {
            clearTimeout(debounceTimeout)
        }

        const timeoutId = setTimeout(() => {
            saveNotes(notes)
        }, 1000)

        // On sauvegarde l'ID du délai en cours pour pouvoir le réinitialiser si besoin
        setDebounceTimeout(timeoutId)
    }

    const saveNotes = (value) => {
        saveClientNotes(value, clientSelected._user?._id)
    }

    if (addNote) {
        return (
            <div className="absolute top-0 w-full overflow-y-auto bg-white h-logged">
                <div onClick={() => setAddNote(false)} className="h-[32px]">
                    <Text.normal className="flex items-center font-medium underline gap-x-2 text-v2_purple px-[4%]">
                        <ArrowLeft color="#72517E" size={24} />
                        Retour
                    </Text.normal>
                </div>
                <div className="h-full pt-[8px] flex flex-col space-y-2 ">
                    <div className="text-center ">
                        <p className="text-[20px] font-semibold leading-[32px] tracking-[-0.4px]">Note sur le client</p>
                        <p className="text-[15px] text-[#979797] leading-[32px] tracking-[-0.3px]">
                            Visible uniquement par vous
                        </p>
                    </div>
                    <div>
                        <div className="grid grid-cols-2 drop-shadow-[0_4px_30px_#5F5F5F1A]">
                            <div
                                className={`py-[19px] items-center flex justify-center text-center text-[18px] leading-[32px] tracking-[-0.36px] ${
                                    view === 'technical'
                                        ? 'bg-v2_lightpurple text-v2_purple font-bold'
                                        : 'bg-white text-[#000] border-b border-[#0000003D] font-medium'
                                }`}
                                onClick={() => setView('technical')}
                            >
                                Note technique
                            </div>
                            <div
                                className={`py-[19px] items-center flex justify-center text-center text-[18px] leading-[32px] tracking-[-0.36px] ${
                                    view === 'other'
                                        ? 'bg-v2_lightpurple text-v2_purple font-bold'
                                        : 'bg-white text-[#000] border-b border-[#0000003D] font-medium'
                                }`}
                                onClick={() => setView('other')}
                            >
                                Autre note
                            </div>
                        </div>
                        <div className="p-[20px] flex flex-col space-y-[4px]">
                            {view === 'technical' && (
                                <>
                                    <input
                                        value={technical.value}
                                        onChange={handleChange}
                                        placeholder="Aucune note renseignée"
                                        id="technical"
                                    />
                                    <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                                        {technical.date ? dayjs(technical.date).format('DD/MM/YYYY') : ''}
                                    </p>
                                </>
                            )}
                            {view === 'other' && (
                                <>
                                    <input
                                        value={other.value}
                                        onChange={handleChange}
                                        placeholder="Aucune note renseignée"
                                        id="other"
                                    />
                                    <p className="text-[14px] text-[#979797] leading-[32px] tracking-[-0.28px]">
                                        {other.date ? dayjs(other.date).format('DD/MM/YYYY') : ''}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div
                className="fixed flex w-full text-white bg-v2_purple justify-between items-center bottom-[80px] pl-[19px] pr-[29px] py-[10px]"
                onClick={() => setAddNote(true)}
            >
                <p className="text-[16px] font-semibold leading-[20px]">Note sur le client</p>
                <Pencil color="#ffffff" size={24} />
            </div>
        )
    }
}

export { ClientDirectory, Notes }
