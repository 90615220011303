import React, { useState } from 'react'

import { ProContext } from '../../../../contexts'
import { RdvList } from '../../../molecules/Schedule'
import { Icon } from '../../../atoms'

import { Info, ChevronDown, ChevronUp } from 'lucide-react'

const Search = ({ searchValue }) => {
    const { bookingsList, selectBooking } = ProContext.Bookings.useBooking()

    const [showAll, setShowAll] = useState(false)

    const displayedBookings = showAll
        ? bookingsList?.passedBookings || []
        : (bookingsList?.passedBookings || []).slice(0, 3)

    return (
        <div>
            {bookingsList?.futureDays && bookingsList?.futureDays.length !== 0 ? (
                bookingsList.futureDays.map((day, index) => {
                    return <RdvList key={index} day={day} />
                })
            ) : (
                <>
                    {searchValue ? (
                        <p className="text-[16px] text-[#979797] text-center py-4">Aucun client trouvé</p>
                    ) : null}
                </>
            )}

            {bookingsList?.passedBookings && (
                <div className="mt-[24px] grid gap-[14px] px-[16px]">
                    <div className="flex items-center mx-auto w-fit space-x-[8px]">
                        <Icon.Book className="w-[36px]" />
                        <p className="text-[14px] font-bold leading-[20px]">Historique des réservations</p>
                    </div>
                    {displayedBookings.length !== 0 ? (
                        displayedBookings.map((booking) => (
                            <div className={`grid gap-[14px]`} key={booking._id} onClick={() => selectBooking(booking)}>
                                <div className="relative flex flex-row items-center justify-between pl-[12px]">
                                    <span
                                        className={`absolute left-0 w-[5px] h-[20px] rounded-t-full rounded-b-full bg-[#9C9C9C]`}
                                    ></span>
                                    <p className="text-[16px] text-[#979797]">
                                        {booking._client?._profil?.firstName} {booking._client?._profil?.lastName}
                                    </p>
                                    <div className="flex flex-row items-center">
                                        <Info size={24} />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-[16px] text-[#979797] text-center py-4">
                            Il n'y a pas de rendez-vous à venir
                        </p>
                    )}

                    {bookingsList?.passedBookings.length > 3 && (
                        <div onClick={() => setShowAll(!showAll)} className="flex flex-row items-center">
                            <p className="text-[16px) text-[#000] mr-2">{showAll ? 'Montrer moins' : 'Montrer plus'}</p>
                            {showAll ? (
                                <ChevronUp onClick={() => setShowAll(!showAll)} color="#000" size={20} />
                            ) : (
                                <ChevronDown onClick={() => setShowAll(!showAll)} color="#000" size={20} />
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default Search
