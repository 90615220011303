import React, { useState, useEffect } from 'react'

import { Text, Input } from '../../atoms'
import { Prestations } from '..'
import { formatHHmmToMilliseconds, formatSecondsToInput } from '../../../utils'

import { Auth, ProContext } from '../../../contexts'

import { Pencil, GripVertical, ChevronDown, ChevronRight, ArrowLeft, Trash2 } from 'lucide-react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

const PrestationForm = () => {
    const { currentPrestation, display, setCurrentPrestation } = ProContext.Prestation.useProPrestation()

    const handleCheckboxChange = (isForfait) => {
        setCurrentPrestation({ ...currentPrestation, forfait: isForfait })
    }

    return (
        <div className="my-4">
            <div className="mb-8">
                {display === 'edit' ? (
                    <Text.h5 color={'v2_purple'} align={'center'} className={'font-medium'}>
                        Modifier une prestation
                    </Text.h5>
                ) : (
                    <Text.h5 color={'v2_purple'} align={'center'} className={'font-medium'}>
                        Ajouter une prestation
                    </Text.h5>
                )}
            </div>
            <div className="mb-8 space-y-2">
                <Text.normal className={'font-medium text-lg'} color={'v2_black'}>
                    Catégorie
                </Text.normal>
                <Prestations.Header
                    value={currentPrestation?.type}
                    onChange={(type) => {
                        setCurrentPrestation({ ...currentPrestation, type: type })
                    }}
                />
            </div>
            <div className="flex mb-8 space-x-6">
                <div className="flex items-center">
                    <Input.Checkbox value={!currentPrestation?.forfait} setValue={() => handleCheckboxChange(false)} />
                    <Text.normal className={'font-semibold'} color={'v2_purple'}>
                        Prestation
                    </Text.normal>
                </div>
                <div className="flex items-center">
                    <Input.Checkbox value={currentPrestation?.forfait} setValue={() => handleCheckboxChange(true)} />
                    <Text.normal className={'font-semibold'} color={'v2_purple'}>
                        Forfait
                    </Text.normal>
                </div>
            </div>
            <div className="mb-8 space-y-2">
                <Text.normal className={'font-medium text-lg'} color={'v2_black'}>
                    Nom de la prestation ou du forfait
                </Text.normal>
                <Input.text
                    placeholder={'Coupe femme'}
                    value={currentPrestation?.name}
                    onChange={(value) => {
                        setCurrentPrestation({ ...currentPrestation, name: value })
                    }}
                />
            </div>
            <div className="mb-8 space-y-4">
                <Text.normal className={'font-medium text-lg'} color={'v2_black'}>
                    Prix
                </Text.normal>
                <Input.Checkbox
                    label={'A partir de'}
                    value={currentPrestation?.isPriceVariable}
                    setValue={() => {
                        setCurrentPrestation({
                            ...currentPrestation,
                            isPriceVariable: !currentPrestation?.isPriceVariable,
                        })
                    }}
                />
                <div className="flex items-center space-x-2">
                    <div className="flex items-center w-1/5">
                        <Input.text
                            placeholder={'0'}
                            value={currentPrestation?.price}
                            onChange={(value) => {
                                setCurrentPrestation({ ...currentPrestation, price: value })
                            }}
                        />
                    </div>
                    <Text.normal className={'font-medium text-lg'} color={'v2_black'}>
                        €
                    </Text.normal>
                </div>
            </div>
            <div className="mb-8 space-y-2">
                <Text.normal className={'font-medium text-lg'} color={'v2_black'}>
                    Durée de prestation
                </Text.normal>
                <Input.Duration
                    value={formatSecondsToInput(currentPrestation?.duration / 1000)}
                    setValue={(value) => {
                        console.log(value)
                        setCurrentPrestation({ ...currentPrestation, duration: formatHHmmToMilliseconds(value) })
                    }}
                />
            </div>
            <div className="mb-8 space-y-2">
                <Text.normal className={'font-medium text-lg'} color={'v2_black'}>
                    Description
                </Text.normal>
                <Input.textarea
                    placeholder={'Décrivez la prestation'}
                    value={currentPrestation?.description}
                    onChange={(value) => {
                        setCurrentPrestation({ ...currentPrestation, description: value })
                    }}
                />
            </div>
        </div>
    )
}

/* eslint no-unused-vars: 0 */
const AllPrestations = ({ currentPageValue, fromPro, onSelectedPrestation }) => {
    const { isLogged, token } = Auth.useAuth()
    const {
        catalogue,
        getCatalogue,
        setCurrentPrestation,
        prestationsOpen,
        forfaitsOpen,
        setPrestationsOpen,
        setForfaitsOpen,
    } = ProContext.Prestation.useProPrestation()
    const { handleSaveAttribute } = ProContext.Profil.useProfilPro()

    const [prestations, setPrestations] = useState(catalogue || [])

    const [prestationFemme, setPrestationFemme] = useState([])
    const [prestationFemmeForfait, setPrestationFemmeForfait] = useState([])

    const [prestationHomme, setPrestationHomme] = useState([])
    const [prestationHommeForfait, setPrestationHommeForfait] = useState([])

    const [prestationEnfant, setPrestationEnfant] = useState([])
    const [prestationEnfantForfait, setPrestationEnfantForfait] = useState([])

    useEffect(() => {
        if (isLogged()) {
            getCatalogue()
        }
    }, [token])

    const sortPrestations = (prestations) => {
        const prestationsFemmes = prestations.filter((p) => p.type === 'Femmes' && !p.forfait)
        setPrestationFemme(prestationsFemmes)
        const prestationsFemmesForfait = prestations.filter((p) => p.type === 'Femmes' && p.forfait)
        setPrestationFemmeForfait(prestationsFemmesForfait)

        const prestationsHommes = prestations.filter((p) => p.type === 'Hommes' && !p.forfait)
        setPrestationHomme(prestationsHommes)
        const prestationsHommesForfait = prestations.filter((p) => p.type === 'Hommes' && p.forfait)
        setPrestationHommeForfait(prestationsHommesForfait)

        const prestationsEnfants = prestations.filter((p) => p.type === 'Enfants' && !p.forfait)
        setPrestationEnfant(prestationsEnfants)
        const prestationsEnfantsForfait = prestations.filter((p) => p.type === 'Enfants' && p.forfait)
        setPrestationEnfantForfait(prestationsEnfantsForfait)

        return prestationsFemmes
            .concat(prestationsFemmesForfait)
            .concat(prestationsHommes)
            .concat(prestationsHommesForfait)
            .concat(prestationsEnfants)
            .concat(prestationsEnfantsForfait)
    }

    useEffect(() => {
        setPrestations(sortPrestations(catalogue))
    }, [catalogue])

    const onDragEnd = (result) => {
        const { destination, source } = result

        if (!destination || destination.index === source.index) {
            return
        }

        if (currentPageValue === 'Femmes') {
            const specificArrayPrestations = prestationFemme
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                specificArrayPrestations
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationFemme(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Hommes') {
            const specificArrayPrestations = prestationHomme
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(specificArrayPrestations)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationHomme(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Enfants') {
            const specificArrayPrestations = prestationEnfant
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(specificArrayPrestations)
                    .concat(prestationEnfantForfait)
            )

            setPrestationEnfant(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
    }

    const onDragEndForfait = (result) => {
        const { destination, source } = result

        if (!destination || destination.index === source.index) {
            return
        }

        if (currentPageValue === 'Femmes') {
            const specificArrayPrestations = prestationFemmeForfait
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(specificArrayPrestations)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationFemmeForfait(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Hommes') {
            const specificArrayPrestations = prestationHommeForfait
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(specificArrayPrestations)
                    .concat(prestationEnfant)
                    .concat(prestationEnfantForfait)
            )

            setPrestationHommeForfait(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
        if (currentPageValue === 'Enfants') {
            const specificArrayPrestations = prestationEnfantForfait
            const [movedItem] = specificArrayPrestations.splice(source.index, 1)
            specificArrayPrestations.splice(destination.index, 0, movedItem)

            const prestationsSorted = sortPrestations(
                prestationFemme
                    .concat(prestationFemmeForfait)
                    .concat(prestationHomme)
                    .concat(prestationHommeForfait)
                    .concat(prestationEnfant)
                    .concat(specificArrayPrestations)
            )

            setPrestationEnfantForfait(specificArrayPrestations)
            setPrestations(prestationsSorted)
            handleSaveAttribute('prestations', prestationsSorted)
        }
    }

    return (
        <div className="p-4">
            <div className="mt-8 mb-8">
                <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => setPrestationsOpen(!prestationsOpen)}
                >
                    <p className="text-[16px] text-v2_purple font-bold mr-2">Prestations</p>
                    {prestationsOpen ? (
                        <ChevronDown size={20} color="#72517e" />
                    ) : (
                        <ChevronRight size={20} color="#72517e" />
                    )}
                </div>
            </div>

            {prestationsOpen && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-prestations">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {prestations
                                    .filter((p) => p.type === currentPageValue && !p.forfait)
                                    .map((i, index) => (
                                        <Draggable draggableId={i._id} index={index} key={i._id}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    className="flex w-full p-3 px-4 py-2 mt-4 mb-3 space-x-2 bg-white rounded-3xl shadow-basic"
                                                    onClick={() => {
                                                        setCurrentPrestation(i)
                                                        if (fromPro) {
                                                            onSelectedPrestation(i)
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="flex items-center justify-center"
                                                        style={{
                                                            minHeight: '50px',
                                                        }}
                                                    >
                                                        <GripVertical size={20} color="#D9D9D9" />
                                                    </div>
                                                    <div>
                                                        <p className="font-medium text-[16px]">{i.name}</p>
                                                        <p className="text-[14px]">{i.description}</p>
                                                        <div className="flex flex-row items-center">
                                                            <p className="text-[12px] underline text-v2_purple mr-2">
                                                                Modifier
                                                            </p>
                                                            <Pencil size={16} color="#72517e" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <div className="mb-8">
                <div
                    className="flex flex-row items-center cursor-pointer"
                    onClick={() => setForfaitsOpen(!forfaitsOpen)}
                >
                    <p className="text-[16px] text-v2_purple font-bold mr-2">Forfaits</p>
                    {forfaitsOpen ? (
                        <ChevronDown size={20} color="#72517e" />
                    ) : (
                        <ChevronRight size={20} color="#72517e" />
                    )}
                </div>
            </div>

            {forfaitsOpen && (
                <DragDropContext onDragEnd={onDragEndForfait}>
                    <Droppable droppableId="droppable-forfaits">
                        {(provided) => (
                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                {prestations
                                    .filter((p) => p.type === currentPageValue && p.forfait)
                                    .map((i, index) => (
                                        <Draggable draggableId={i._id} index={index} key={i._id}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={{
                                                        ...provided.draggableProps.style,
                                                    }}
                                                    className="flex w-full p-3 px-4 py-2 mt-4 mb-3 space-x-2 bg-white rounded-3xl shadow-basic"
                                                    onClick={() => {
                                                        setCurrentPrestation(i)
                                                        if (fromPro) {
                                                            onSelectedPrestation(i)
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        className="flex items-center justify-center"
                                                        style={{
                                                            minHeight: '50px',
                                                        }}
                                                    >
                                                        <GripVertical size={20} color="#D9D9D9" />
                                                    </div>
                                                    <div>
                                                        <p className="font-medium text-[16px]">{i.name}</p>
                                                        <p className="text-[14px]">{i.description}</p>
                                                        <div className="flex flex-row items-center">
                                                            <p className="text-[12px] underline text-v2_purple mr-2">
                                                                Modifier
                                                            </p>
                                                            <Pencil size={16} color="#72517e" />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )}
        </div>
    )
}

const HeaderPrestations = ({ onSkip, onGoBack }) => {
    const { display, deletePrestation } = ProContext.Prestation.useProPrestation()

    return (
        <div className="flex flex-row items-center justify-between w-full px-4">
            <div className="flex flex-row items-center" onClick={() => onGoBack()}>
                <ArrowLeft size={24} color="#72517e" />
                <p className="underline text-[16px] text-v2_purple ml-1">Retour</p>
            </div>
            {onSkip ? (
                <div onClick={() => onSkip()} className="flex flex-row items-center">
                    <p className="underline text-[16px] text-v2_purple mr-1">Passer</p>
                    <ChevronRight size={24} color="#72517e" />
                </div>
            ) : (
                display && (
                    <div onClick={() => deletePrestation()} className="flex flex-row items-center">
                        <Trash2 size={24} />
                    </div>
                )
            )}
        </div>
    )
}

export { PrestationForm, AllPrestations, HeaderPrestations }
