export const openIndexedDB = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('agenda-database', 1)

        request.onupgradeneeded = (event) => {
            const database = event.target.result

            if (!database.objectStoreNames.contains('months')) {
                database.createObjectStore('months', { keyPath: 'monthKey' })
            }
        }

        request.onsuccess = (event) => {
            resolve(event.target.result)
        }

        request.onerror = (event) => {
            console.error(event)
            reject(event.target.errorCode)
        }
    })
}

export const getAllMonth = (database) => {
    return new Promise((resolve, reject) => {
        const transaction = database.transaction('months', 'readonly')
        const store = transaction.objectStore('months')
        const request = store.getAll()

        request.onsuccess = () => {
            resolve(request.result)
        }

        request.onerror = () => {
            reject('Erreur de récupération des données')
        }
    })
}

export const getOneMonth = (database, month) => {
    return new Promise((resolve, reject) => {
        const transaction = database.transaction('months', 'readonly')
        const store = transaction.objectStore('months')
        const request = store.get(month)

        request.onsuccess = () => {
            resolve(request.result)
        }

        request.onerror = () => {
            reject('Erreur de récupération des données')
        }
    })
}


export const upsertMonthInStore = (database, month) => {
    return new Promise((resolve, reject) => {
        const transaction = database.transaction('months', 'readwrite')
        const store = transaction.objectStore('months')
        const request = store.put(month)

        request.onsuccess = () => {
            resolve(request.result)
        }

        request.onerror = () => {
            reject('Erreur d’ajout de l’élément')
        }
    })
}
