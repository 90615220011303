import React from 'react'
import _ from 'lodash'
import dayjs from 'dayjs'

import { Profesionnal } from '../../../organisms'
import { Booking } from '../../../molecules/Pro'

import { ProContext } from '../../../../contexts'

const Waitings = () => {
    const { bookingsWaiting, bookingSelected, step, selectBooking } = ProContext.Bookings.useBooking()

    const calculDateNeed = (bookingsWaiting, index) => {
        if (index === 0) return true
        else {
            if (!_.isEqual(bookingsWaiting[index - 1].date, bookingsWaiting[index].date)) return true
        }
        return false
    }

    return (
        <div className="fixed top-client w-screen left-0 grid gap-[12px] h-logged overflow-y-auto pb-[50px]">
            {!bookingSelected ? (
                bookingsWaiting ? (
                    bookingsWaiting.length > 0 ? (
                        <div className="flex flex-col space-y-[24px]">
                            {bookingsWaiting.map((booking, index) => {
                                const needAddress = calculDateNeed(bookingsWaiting, index)
                                return (
                                    <>
                                        {needAddress && (
                                            <p className="text-[12px] font-bold text-v2_white leading-[20px] pl-[11px] text-center bg-v2_purple sticky w-screen uppercase mb-[10px] top-0 shadow-waiting h-fit*/9*">
                                                {dayjs(booking.date).format('ddd DD MMMM')}
                                            </p>
                                        )}
                                        <Booking.WaitingCard
                                            booking={booking}
                                            index={index}
                                            onClick={() => {
                                                selectBooking(booking)
                                            }}
                                        />
                                    </>
                                )
                            })}
                        </div>
                    ) : (
                        <p className="text-center text-[16px] text-[#979797] mt-10">
                            Aucun RDV en attente de confirmation
                        </p>
                    )
                ) : (
                    <p>Chargement des RDV</p>
                )
            ) : (
                <div className={`${bookingSelected && !step ? 'block' : 'hidden'}`}>
                    {bookingSelected && !step && <Profesionnal.Booking.Screen />}
                </div>
            )}
        </div>
    )
}

export default Waitings
