import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Heart } from 'lucide-react'

import { Cards, Input, Text, Modal, Button, Loader } from '../../../components/atoms'
import { Return } from '../../../components/molecules'

import { Address, ClientContext, Auth } from '../../../contexts'

import { renderNamePro } from '../../../utils'

const Results = () => {
    const navigate = useNavigate()

    const { isLogged } = Auth.useAuth()
    const { professionalFound, professionalFiltered, setStep, step, selectedAddress } = Address.useAddress()
    const { favoriteQueu, setFavoriteQueu, favorite, putFavorites } = ClientContext.Favorite.useFavorite()
    const { setProfil, setBackUrl } = ClientContext.Reservation.useReservation()

    const [authModalOpen, setAuthModalOpen] = useState(false)
    const [favoriteModalOpen, setFavoriteModalOpen] = useState(true)
    const favoritePopUpSeen = JSON.parse(localStorage.getItem('favoritePopUpSeen'))

    const [deletingFavorite, setDeletingFavorite] = useState()
    const [addedFavorite, setAddedFavorite] = useState()

    const isFavorite = (pro) => {
        let favoritePresence = false
        favorite?.map((fav) => {
            if (fav._owner._id == pro._id) {
                favoritePresence = true
            }
        })

        return favoritePresence
    }

    useEffect(() => {
        if (professionalFound.length === 0 && step === 'results' && !selectedAddress) setStep('home')
    }, [])

    return (
        <div className="bg-white">
            {!isLogged() && !favoritePopUpSeen ? (
                <Modal.Favorite
                    title={''}
                    isOpen={favoriteModalOpen}
                    setIsOpen={() => {
                        localStorage.setItem('favoritePopUpSeen', JSON.stringify(true))
                        setFavoriteModalOpen(!favoriteModalOpen)
                    }}
                >
                    <Text.h5 color={'#000'} align={'center'}>
                        Cliquez sur le <b>coeur</b> pour ajouter le professionnel de beauté à vos <b>favoris</b>
                    </Text.h5>
                    <div
                        className="flex flex-row justify-center w-full my-6"
                        onClick={() => {
                            localStorage.setItem('favoritePopUpSeen', JSON.stringify(true))
                            setFavoriteModalOpen(!favoriteModalOpen)
                        }}
                    >
                        <Heart color="#DFCEEA" fill="#DFCEEA" size={60} />
                    </div>
                </Modal.Favorite>
            ) : (
                <>
                    <Modal.Choice isOpen={deletingFavorite} setIsOpen={() => setDeletingFavorite()}>
                        <p className="text-justify">
                            Vous shouaitez supprimer {deletingFavorite?.firstName} {deletingFavorite?.lastName} de vos
                            favoris ?
                        </p>
                        <div className="flex space-x-2 mt-[29px]">
                            <Button.secondary
                                onClick={async () => {
                                    let newFavorites = [...favorite]
                                    let indexToRemove

                                    favorite.map((fav, index) => {
                                        if (fav._owner._id === deletingFavorite._owner._id) {
                                            indexToRemove = index
                                        }
                                    })

                                    newFavorites.splice(indexToRemove, 1)
                                    await putFavorites(newFavorites.map((fav) => fav._owner))
                                    setDeletingFavorite()
                                }}
                                title="Oui"
                            />
                            <Button.secondary
                                onClick={() => {
                                    setDeletingFavorite()
                                }}
                                title="Non"
                            />
                        </div>
                    </Modal.Choice>
                    <Modal.Choice isOpen={addedFavorite} onClick={() => setAddedFavorite()}>
                        <p className="text-justify">
                            Vous avez ajouté {addedFavorite?.firstName} {addedFavorite?.lastName} à vos favoris
                        </p>
                        <div className="mx-auto mt-[29px]">
                            <Button.secondary
                                onClick={async () => {
                                    setBackUrl('/client')
                                    navigate(`/client/pro/profil/${addedFavorite._owner}`)
                                }}
                                title="Prendre RDV"
                            />
                        </div>
                    </Modal.Choice>
                </>
            )}

            {step === 'loading' ? (
                <div className="w-full">
                    <Loader.Primary />
                    <Text.h4>L'algorithme recherche les meilleures options pour vous</Text.h4>
                </div>
            ) : (
                <div className="w-full mx-auto pb-[18px]">
                    {location.pathname.indexOf('/client') === -1 && (
                        <Return.Primary onClick={() => setStep('address')} />
                    )}
                    <div className="flex flex-col space-y-[12px] w-full">
                        <div className="flex flex-col space-y-[7px] w-full mx-auto">
                            <div className="bg-white shadow-modal rounded-[30px]">
                                <Input.searchFiltered />
                            </div>
                            <Text.small color={'#000'} align={'center'}>
                                Sélectionnez votre professionnel de beaute
                            </Text.small>
                        </div>
                        <div className="grid gap-[18px] mt-[12px]">
                            {professionalFound
                                ? professionalFiltered.length === 0
                                    ? professionalFound.map((pro, index) => {
                                          return (
                                              <Cards.Pro
                                                  onClick={async () => {
                                                      await setProfil(pro)
                                                      if (!isLogged()) {
                                                          setBackUrl('/')
                                                          navigate(`/profil/${pro._id}`)
                                                      } else {
                                                          setBackUrl('/client')
                                                          navigate(`/client/pro/profil/${pro._id}`)
                                                      }
                                                  }}
                                                  name={renderNamePro(pro._profil)}
                                                  prestations={pro._profil._specialities?.map((j) => j)}
                                                  onFavorite={async () => {
                                                      if (!isLogged()) {
                                                          setAuthModalOpen(!authModalOpen)
                                                          setFavoriteQueu([...favoriteQueu, pro._id])
                                                      } else {
                                                          let newFavorites = [...favorite]

                                                          if (isFavorite(pro)) {
                                                              setDeletingFavorite(pro._profil)
                                                          } else {
                                                              newFavorites.push(pro._profil)
                                                              await putFavorites(newFavorites.map((fav) => fav._owner))
                                                              setAddedFavorite(pro._profil)
                                                          }
                                                      }
                                                  }}
                                                  cover={pro._profil.cover?.thumbnailUrl}
                                                  categories={pro._profil.category}
                                                  favoriState={isFavorite(pro)}
                                                  key={index}
                                              />
                                          )
                                      })
                                    : professionalFiltered.map((pro, index) => {
                                          return (
                                              <Cards.Pro
                                                  onClick={async () => {
                                                      await setProfil(pro)
                                                      if (!isLogged()) {
                                                          setBackUrl('/')
                                                          navigate(`/profil/${pro._id}`)
                                                      } else {
                                                          setBackUrl('/client')
                                                          navigate(`/client/pro/profil/${pro._id}`)
                                                      }
                                                  }}
                                                  name={`${pro._profil.firstName} ${pro._profil.lastName}`}
                                                  prestations={pro._profil._specialities?.map((j) => j)}
                                                  onFavorite={async () => {
                                                      if (!isLogged()) {
                                                          setAuthModalOpen(!authModalOpen)
                                                          setFavoriteQueu([...favoriteQueu, pro._id])
                                                      } else {
                                                          let newFavorites = [...favorite]

                                                          if (isFavorite(pro)) {
                                                              setDeletingFavorite(pro._profil)
                                                          } else {
                                                              newFavorites.push(pro._profil)
                                                              await putFavorites(newFavorites.map((fav) => fav._owner))
                                                              setAddedFavorite(pro._profil)
                                                          }
                                                      }
                                                  }}
                                                  cover={pro._profil.cover?.thumbnailUrl}
                                                  categories={pro._profil.category}
                                                  favoriState={isFavorite(pro)}
                                                  key={index}
                                              />
                                          )
                                      })
                                : null}
                            {professionalFound.length === 0 && (
                                <p className="text-[20px] text-[#000] text-center">Aucun professionnel trouvé</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
            {!isLogged() && (
                <Modal.Favorite
                    title={'Bienvenue'}
                    isOpen={authModalOpen}
                    setIsOpen={() => setAuthModalOpen(!authModalOpen)}
                >
                    <div className="my-6">
                        <Text.h5 color={'#000'} align={'center'}>
                            Créer un profil pour ajouter aux favoris
                        </Text.h5>
                        <Button.primary title={"S'inscrire"} onClick={() => navigate('/signup')} />
                    </div>
                    <Text.h5 color={'#000'} align={'center'}>
                        Vous avez déjà un compte ?
                    </Text.h5>
                    <Button.secondary title={'Se connecter'} onClick={() => navigate('/login')} />
                </Modal.Favorite>
            )}
        </div>
    )
}

export default Results
