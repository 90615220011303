import React from 'react'
import { X, Check, History } from 'lucide-react'

import { ProContext } from '../../contexts'
import { FaWaze } from 'react-icons/fa6'

import { Browser } from '@capacitor/browser'

const Waiting = ({ bookingSelected, handleConfirm }) => {
    const { selectBooking, setModal } = ProContext.Bookings.useBooking()

    return (
        <div className="grid grid-cols-3 h-[56px]">
            <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('cancel')
                }}
            >
                <X size={24} color="#FC4F4F" />
                <p className="text-[12px] font-medium">Annuler</p>
            </div>
            <div
                className="flex flex-col items-center justify-center border-l border-r border-[#979797]"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('move')
                }}
            >
                <History size={24} color="#FB9639" />
                <p className="text-[12px] font-medium">Décaler</p>
            </div>
            <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                    if (handleConfirm) {
                        handleConfirm()
                    } else {
                        selectBooking(bookingSelected)
                    }
                }}
            >
                <Check size={24} color="#269B13" />
                <p className="text-[12px] font-medium">Confirmer</p>
            </div>
        </div>
    )
}

const Confirm = ({ bookingSelected }) => {
    const { selectBooking, setModal } = ProContext.Bookings.useBooking()

    const openWaze = () => {
        const wazeUrl = `https://waze.com/ul?ll=${bookingSelected.address.lat},${bookingSelected.address.lon}&navigate=yes`
        Browser.open({ url: wazeUrl })
    }

    return (
        <div className="grid grid-cols-3 h-[56px]">
            <div
                className="flex flex-col items-center justify-center"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('cancel')
                }}
            >
                <X size={24} color="#FC4F4F" />
                <p className="text-[12px] font-medium">Annuler</p>
            </div>
            <div
                className="flex flex-col items-center justify-center border-l border-r border-[#979797]"
                onClick={() => {
                    selectBooking(bookingSelected)
                    setModal('move')
                }}
            >
                <History size={24} color="#FB9639" />
                <p className="text-[12px] font-medium">Décaler</p>
            </div>
            <div className="flex flex-col items-center justify-center" onClick={openWaze}>
                <FaWaze size={24} />
                <p className="text-[12px] font-medium">Waze</p>
            </div>
        </div>
    )
}

export { Waiting, Confirm }
