import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Cards, Loader, Text, Carrousel, Modal } from '../../../components/atoms'
import { Return } from '../../../components/molecules'
import { Prestations } from '../../../components/organisms'

import { ClientContext } from '../../../contexts'

const Export = () => {
    const navigate = useNavigate()
    const { profil, loading, prestationDetail, setPrestationDetail } = ClientContext.Reservation.useReservation()

    const [selectedImage, setSelectedImage] = useState()

    const openModal = (image) => {
        setSelectedImage(image)
    }

    const closeModal = () => {
        setSelectedImage(null)
    }

    const [selectedType, setSelectedType] = useState('Femmes')

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        if (container) {
            container.scrollTo(0, 0)
        }
    }, [])

    return (
        <div>
            {profil ? (
                !loading ? (
                    <>
                        <Return.Primary
                            className="p-4 sticky top-[-20px] bg-white z-[51]"
                            onClick={() => {
                                if (prestationDetail) setPrestationDetail()
                                else navigate(`/client/pro/profil/${profil._id}`)
                            }}
                        />
                        {!prestationDetail ? (
                            <>
                                <div className="py-4 mx-4">
                                    <Cards.ProProfil
                                        name={`${profil?._profil.firstName} ${profil?._profil.lastName}`}
                                        experience={profil?._profil.yearsExperience}
                                        gender={profil?._profil.sexe}
                                        ppCoiffeur={profil?._profil?.avatar?.thumbnailUrl}
                                    />
                                </div>
                                <Prestations.Selecting
                                    prestations={profil?._profil._prestations}
                                    to={`/client/pro/prestation`}
                                    selectedType={selectedType}
                                    setSelectedType={(value) => setSelectedType(value)}
                                />
                            </>
                        ) : (
                            <>
                                <div className="flex flex-col w-full pt-10 space-y-10">
                                    <Text.h4 className="font-bold text-center">{prestationDetail.name}</Text.h4>
                                    <Text.normal className="my-2 leading-8">{prestationDetail.description}</Text.normal>
                                    {prestationDetail?._photos?.length > 0 && (
                                        <Carrousel.Gallery images={prestationDetail._photos} onImageClick={openModal} />
                                    )}
                                    {selectedImage && (
                                        <Modal.Image selectedImage={selectedImage} closeModal={closeModal} />
                                    )}
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    <div>
                        <Loader.Primary />
                        <Text.h4 className="text-center">
                            L'algorithme recherche les meilleures options pour vous
                        </Text.h4>
                    </div>
                )
            ) : (
                <div>
                    <Loader.Primary />
                    <Text.h4 className="text-center">Récupération du profil</Text.h4>
                </div>
            )}
        </div>
    )
}

export default Export
