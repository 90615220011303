import React, { useEffect } from 'react'

import { Header } from '../../components/atoms'
import { Return, Pro as ProMolecule } from '../../components/molecules'
import { Profesionnal } from '../../components/organisms'
import { Pro } from '../../components/templates'

import { ProContext } from '../../contexts'

function ClientDirectory() {
    const { clientSelected, settingClientSelected } = ProContext.Directory.useDirectory()
    const { selectBooking, bookingSelected } = ProContext.Bookings.useBooking()

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        if (container) container.scrollTo(0, 0)
    }, [clientSelected])

    return (
        <div className='relative'>
            {!clientSelected ? (
                <>
                    <Header.ClientDirectory />
                    <Profesionnal.Directory.ClientDirectory />
                </>
            ) : !clientSelected._id ? (
                <>
                    <Return.Primary onClick={() => settingClientSelected()} />
                    <ProMolecule.Directory.ClientForm />
                </>
            ) : (
                <>
                    <Return.Primary
                        onClick={() => {
                            if (bookingSelected) {
                                selectBooking()
                            } else {
                                settingClientSelected()
                            }
                        }}
                    />

                    <Pro.ClientProfile />
                    <Profesionnal.Directory.Notes />
                </>
            )}
        </div>
    )
}

export default ClientDirectory
