import React, { useState } from 'react'
import { Text, Cards, Input } from '.'
import { House, MapPin, Plus, ChevronDown } from 'lucide-react'

import { Address, ProContext } from '../../contexts'

import { formatHHmmToMilliseconds, formatSecondsToInput } from '../../utils'

const TimeInput = ({ value, setValue }) => {
    const [isActive, setIsActive] = useState(false)

    return (
        <div className="">
            <div
                onClick={() => {
                    setIsActive(true)
                }}
            >
                {value}
            </div>
            <div className={`fixed top-0 bottom-0 left-0 h-[100vh] w-[100vw] z-[90] ${isActive ? 'flex' : 'hidden'}`}>
                <div className="w-full h-full bg-white bg-opacity-70" onClick={() => setIsActive(false)}></div>
                <div className={`w-[100vw] h-fit bg-white shadow-modal absolute bottom-0 p-6`}>
                    <Input.Duration
                        value={value}
                        setValue={(value) => {
                            setValue(value)
                        }}
                        isVisible={isActive}
                    />
                </div>
            </div>
        </div>
    )
}

const IconsHoraires = ({ bloc, setBloc, text }) => {
    const place = ProContext.Place.usePlace()
    const { setStep } = Address.useAddress()
    const [openDropdown, setOpenDropdown] = useState(false)

    return (
        <div className="grid gap-0">
            {text}
            <div className="flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                    <Cards.iconsLucide
                        title={'Chez client'}
                        icon={<House color={bloc.moving ? '#72517E' : '#6c6c6c'} size={15} />}
                        onClick={() => {
                            setBloc({ ...bloc, moving: true, place: null })
                        }}
                        selected={bloc.moving}
                    />
                    <div className="mx-1">
                        <Text.small color={'black'} className={'font-medium'}>
                            ou
                        </Text.small>
                    </div>
                    <Cards.iconsLucide
                        title={'Chez pro'}
                        icon={<MapPin color={!bloc.moving ? '#72517E' : '#6c6c6c'} size={15} />}
                        onClick={() => {
                            setBloc({ ...bloc, moving: false })
                        }}
                        selected={!bloc.moving}
                    />
                </div>
                <div className="flex flex-row items-center">
                    <div className="py-1 px-3 border border-[#72517E] rounded-full">
                        <TimeInput
                            value={formatSecondsToInput(bloc.start)}
                            setValue={(value) => {
                                setBloc({ ...bloc, start: formatHHmmToMilliseconds(value) / 1000 })
                            }}
                        />
                    </div>
                    <div className="mx-1">
                        <Text.small color={'black'} className={'font-medium'}>
                            à
                        </Text.small>
                    </div>
                    <div className="p-1 px-3 border rounded-full border-[#72517E]">
                        <TimeInput
                            value={formatSecondsToInput(bloc.end)}
                            setValue={(value) => {
                                setBloc({ ...bloc, end: formatHHmmToMilliseconds(value) / 1000 })
                            }}
                        />
                    </div>
                </div>
            </div>
            {bloc.moving ? (
                <p className="mt-[12px] font-[16px] leading-[20px]">Disponible pour travailler chez le client</p>
            ) : (
                <div className="flex items-start flex-row justify-between mt-[18px]">
                    <p className="w-[35%]">Lieu sur place :</p>
                    <div
                        className="flex flex-row items-center justify-between w-[63%] px-2 py-1 ml-2 border border-v2_purple rounded-2xl"
                        onClick={() => setOpenDropdown(!openDropdown)}
                    >
                        <div>
                            <Text.small color={'black'}>
                                {bloc?.place ? bloc.place.name : "Ajouter l'adresse"}
                            </Text.small>
                            {openDropdown && (
                                <div>
                                    {place.list.map((placeDetail) => {
                                        if (placeDetail?._id !== bloc.place?._id)
                                            return (
                                                <div
                                                    key={placeDetail._id}
                                                    className="mt-1"
                                                    onClick={() => setBloc({ ...bloc, place: placeDetail })}
                                                >
                                                    <Text.small color={'black'}>{placeDetail.name}</Text.small>
                                                </div>
                                            )
                                    })}
                                    <div
                                        className="mt-1"
                                        onClick={() => {
                                            setStep('address')
                                            place.setStep('create')
                                        }}
                                    >
                                        <Text.small className={'opacity-50'}>Ajouter l'adresse</Text.small>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="self-start">
                            <ChevronDown color="#000" size={20} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const TimingRDV = ({ day, onChange }) => {
    // Si le tableau de blocs est vide, on définit un bloc par défaut

    return (
        <div className="grid gap-[12px] pb-[12px]">
            <div className="flex flex-row items-center">
                <div className="my-2">
                    <Text.normal className={'font-medium capitalize'}>{day.name}</Text.normal>
                </div>
                <div className="mx-2">
                    <input
                        type="checkbox"
                        className="accent-v2_purple"
                        checked={!day.isWorkingDay}
                        onChange={() => onChange({ ...day, isWorkingDay: !day.isWorkingDay })}
                    />
                </div>
                <Text.normal>Repos</Text.normal>
            </div>
            <div className="grid gap-[16px]">
                {day.isWorkingDay &&
                    day.blocs.map((bloc, index) => (
                        <IconsHoraires
                            key={index}
                            text={
                                day.blocs.length === 2 && (
                                    <p className="text-[16px] font-medium leading-[24px] mb-[12px]">
                                        {index === 0 ? 'Matin' : 'Après-midi'}
                                    </p>
                                )
                            }
                            bloc={bloc}
                            setBloc={(value) => {
                                const blocsCopy = [...day.blocs]
                                blocsCopy[index] = value

                                onChange({
                                    ...day,
                                    blocs: blocsCopy,
                                })
                            }}
                        />
                    ))}
            </div>

            {day.blocs.length === 1 && day.isWorkingDay && (
                <div
                    className="flex flex-row items-center mt-[24px] mb-[18px]"
                    onClick={() => {
                        const blocsCopy = [...day.blocs]
                        blocsCopy.push({
                            ...day.blocs[0],
                            start: day.blocs[0].end + 3600,
                            end: day.blocs[0].end + 7200,
                        })

                        onChange({ ...day, blocs: blocsCopy })
                    }}
                >
                    <Plus color="#72517E" size={15} />
                    <Text.small className={'ml-2 underline'}>Ajouter un autre créneau horaire</Text.small>
                </div>
            )}
        </div>
    )
}

export { TimingRDV }
