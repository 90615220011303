import React, { useEffect } from 'react'
import { Request } from '../../contexts'
import { Check } from 'lucide-react'

const Info = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] border-[1.5px] rounded-full shadow-modal z-[99]">
            <div className="grid gap-2 px-6 py-4">
                <div className="text-sm font-bold text-[#000]">Information</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Warning = ({ message }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-white w-[90vw] fixed left-[5vw] rounded-full shadow-error z-[99]">
            <div className="grid gap-2 px-6 py-4">
                <div className="text-sm font-bold text-v2_red">Attention !</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

const Success = ({ message, noIcon }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div className=" bg-[#F5F5F5] w-[90vw] fixed left-[5vw] shadow-toast z-[99] rounded-[20px] px-[9%] py-[35.5px] flex space-x-[10px] items-center justify-center">
            {!noIcon && (
                <div className="size-[30px] bg-[#37942F] rounded-full flex items-center justify-center">
                    <Check color="#EDEDED" size={20} />
                </div>
            )}
            <div className="text-[16px] font-semibold leading-[24px] text-center w-[85%]">{message}</div>
        </div>
    )
}

const Error = ({ message, relative }) => {
    const { setMessage } = Request.useRequest()

    useEffect(() => {
        setTimeout(() => {
            setMessage({ type: 'info', message: null })
        }, 5000)
    }, [])

    return (
        <div
            className={`bg-white w-[90vw] left-[5vw]  rounded-[50px] shadow-error z-[99] ${
                relative ? 'relative' : 'fixed'
            }`}
        >
            <div className="grid px-[24px] py-[10px]">
                <div className="text-sm leading-[20px] tracking-[-0.56px] font-bold text-v2_red">Attention !</div>
                <div className="text-xs">{message}</div>
            </div>
        </div>
    )
}

export { Info, Warning, Success, Error }
