import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'lucide-react'

import { Text, Button, Modal, Icon } from '../atoms'

import { ClientContext, Auth } from '../../contexts'

export const Primary = ({ children, className, onClick, customText }) => {
    return (
        <div className={`flex bg-white ${className ? className : ''}`}>
            <div
                onClick={() => {
                    if (onClick) {
                        onClick()
                    }
                }}
            >
                <Text.normal className="flex items-center gap-x-2 underline font-medium text-v2_purple">
                    <ArrowLeft color="#72517E" size={24} />
                    {customText ? customText : 'Retour'}
                </Text.normal>
            </div>
            {children}
        </div>
    )
}

export const Favorites = ({ children, className, onClick }) => {
    const navigate = useNavigate()

    const { isLogged } = Auth.useAuth()
    const { favoriteQueu, setFavoriteQueu, favorite, putFavorites } = ClientContext.Favorite.useFavorite()
    const { profil } = ClientContext.Reservation.useReservation()

    const [authModalOpen, setAuthModalOpen] = useState(false)

    const [deletingFavorite, setDeletingFavorite] = useState()

    const isFavorite = (pro) => {
        let favoritePresence = false

        favorite?.map((fav) => {
            if (fav?._owner?._id == pro?._id) {
                favoritePresence = true
            }
        })

        return favoritePresence
    }

    const [isSticky, setIsSticky] = useState(false)

    useEffect(() => {
        const handleScroll = (event) => {
            setIsSticky(event.target.scrollTop !== 0)
        }

        const unloggedContainer = document.getElementsByClassName('h-unlogged')[0]
        if (unloggedContainer) {
            unloggedContainer.addEventListener('scroll', handleScroll)
            return () => {
                unloggedContainer.removeEventListener('scroll', handleScroll)
            }
        }

        const loggedContainer = document.getElementsByClassName('h-logged')[0]
        if (loggedContainer) {
            loggedContainer.addEventListener('scroll', handleScroll)
            return () => {
                loggedContainer.removeEventListener('scroll', handleScroll)
            }
        }
    }, [])

    return (
        <>
            {!isLogged() ? (
                <>
                    <Modal.Favorite title={''} isOpen={authModalOpen} setIsOpen={() => setAuthModalOpen(false)}>
                        <div className="grid gap-[20px]">
                            <Text.h4 color={'#72517E'} align={'center'}>
                                Bienvenue
                            </Text.h4>
                            <div className="grid gap-[24px]">
                                <div className="grid gap-[8px]">
                                    <Text.h5 color={'#000'} align={'center'}>
                                        Créer un profil pour ajouter aux favoris
                                    </Text.h5>
                                    <Button.primary
                                        title="S'inscrire"
                                        onClick={() => {
                                            setFavoriteQueu([...favoriteQueu, profil._id])
                                            navigate('/signup')
                                        }}
                                    />
                                </div>
                                <div className="grid gap-[8px] justify-center">
                                    <Text.normal>Vous avez déjà un compte ?</Text.normal>
                                    <Button.secondary
                                        title="Se connecter"
                                        onClick={() => {
                                            setFavoriteQueu([...favoriteQueu, profil._id])
                                            navigate('/login')
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Favorite>
                </>
            ) : (
                <>
                    <Modal.Choice isOpen={deletingFavorite} setIsOpen={() => setDeletingFavorite()}>
                        <p className="text-justify">
                            Vous shouaitez supprimer {deletingFavorite?.firstName} {deletingFavorite?.lastName} de vos
                            favoris ?
                        </p>
                        <div className="flex space-x-2 mt-[29px]">
                            <Button.secondary
                                onClick={async () => {
                                    let newFavorites = [...favorite]
                                    let indexToRemove

                                    favorite.map((fav, index) => {
                                        if (fav._owner._id === deletingFavorite._owner) {
                                            indexToRemove = index
                                        }
                                    })

                                    newFavorites.splice(indexToRemove, 1)
                                    await putFavorites(newFavorites.map((fav) => fav._owner))
                                    setDeletingFavorite()
                                }}
                                title="Oui"
                            />
                            <Button.secondary
                                onClick={() => {
                                    setDeletingFavorite()
                                }}
                                title="Non"
                            />
                        </div>
                    </Modal.Choice>
                </>
            )}
            <div className={`sticky top-[-20px] bg-white flex justify-between z-[30] ${className ? className : ''}`}>
                <div
                    onClick={() => {
                        if (onClick) {
                            onClick()
                        }
                    }}
                >
                    <Text.normal className="flex items-center gap-x-2 underline font-medium text-v2_purple">
                        <ArrowLeft color="#72517E" size={24} />
                        Retour
                    </Text.normal>
                </div>
                {children}
                <div
                    onClick={async () => {
                        if (!isLogged()) {
                            setAuthModalOpen(true)
                        } else {
                            let newFavorites = [...favorite]

                            if (isFavorite(profil)) {
                                setDeletingFavorite(profil._profil)
                            } else {
                                newFavorites.push(profil._profil)
                                await putFavorites(newFavorites.map((fav) => fav._owner))
                            }
                        }
                    }}
                    className={`${isSticky ? 'hidden' : 'inline-block'}`}
                >
                    <Text.normal className="flex flex-col items-center font-normal">
                        {isFavorite(profil) ? (
                            <div className='bg-[#DFCDEA] py-[4px] px-[9px] rounded-[30px]'>
                                <Icon.Heart className={`w-8 h-8 text-v2_purple`} />
                            </div>
                        ) : (
                            <Icon.Heart className={`w-8 h-8`} />
                        )}
                        Favoris
                    </Text.normal>
                </div>
            </div>
        </>
    )
}
