import React, { useEffect, useState } from 'react'
import { Text } from '../../../atoms'
import { Return } from '../../../molecules'
import { Client } from '../../../organisms'
import { ClientContext, Address, Auth } from '../../../../contexts'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

export default function Main({ goToStep }) {
    const navigate = useNavigate()

    const { isLogged } = Auth.useAuth()
    const { profil, days, prestationsSelected, setFirstDayOfCalcul, setDays, getDays, firstDayOfCalcul } =
        ClientContext.Reservation.useReservation()
    const { selectedAddress, setStep } = Address.useAddress()
    const [fetched, setFetched] = useState(false)
    const [firstSevenDays, setFirstSevenDays] = useState([])

    useEffect(() => {
        setDays([])
        setFirstDayOfCalcul(dayjs().format('YYYY-MM-DD'))
    }, [])

    useEffect(() => {
        if (days?.length === 0 && !fetched) {
            setFetched(true)
            getDays(firstDayOfCalcul)
        } else {
            if (days) {
                if (days?.length > 7) {
                    let localDays = [...days]
                    setFirstSevenDays(localDays.splice(0, 7))
                } else {
                    setDays(days)
                }
            }
        }
    }, [days])

    const goBackFunc = () => {
        if (profil?._id)
            if (isLogged()) {
                navigate(`/client/pro/prestations/${profil._id}`)
            } else {
                navigate(`/prestations/${profil._id}`)
            }
        else navigate(-1)
    }

    return (
        <div>
            <Return.Primary className="p-4 sticky top-[-20px] bg-white z-[51]" onClick={() => goBackFunc()} />
            <div className="mt-4">
                <Client.Reservation.Address
                    address={selectedAddress}
                    modifyAddress={() => {
                        setStep('address')
                        navigate('/')
                    }}
                />
                <Client.Reservation.Prestations.Prestations prestations={prestationsSelected} />
                <div onClick={() => navigate(`/prestations/${profil._id}`)}>
                    <Text.small className="font-medium text-center underline text-v2_purple">
                        Ajouter une prestation
                    </Text.small>
                </div>
            </div>
            {days?.length > 0 ? (
                <div className="flex flex-col mt-4">
                    <Text.normal className="pb-4 font-semibold text-v2_purple text-[18px] ml-[24px]">
                        Choix de la date et de l'heure
                    </Text.normal>
                    {days.length > 7
                        ? firstSevenDays.map(({ date, bookings }) => (
                              <Client.Reservation.Date goToStep={goToStep} key={date} date={date} horaires={bookings} />
                          ))
                        : days.map(({ date, bookings }) => (
                              <Client.Reservation.Date goToStep={goToStep} key={date} date={date} horaires={bookings} />
                          ))}
                </div>
            ) : (
                <Text.normal className="py-10 text-center">Aucun rendez-vous disponible cette semaine</Text.normal>
            )}
            <div onClick={() => goToStep(2)}>
                <Text.small className="py-2 font-medium text-center">Afficher plus de disponibilités</Text.small>
            </div>
        </div>
    )
}
