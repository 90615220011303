/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect } from 'react'

import { useRequest } from '../../request'
import { Auth } from '../..'
import { select } from 'slate'

const DirectoryContext = createContext({
    clients: Array,
    clientSelected: Object,
    addNote: Boolean,
    setAddNote: (value) => {},
    getDirectory: async () => {},
    createClient: async (firstName, lastName, address, phoneNumber, email) => {},
    editClient: async (email, address, phoneNumber, phoneNumberChanged, addressChanged, emailChanged, clientId) => {},
    deleteClient: async (clientId) => {},
    searchClient: async (query, sort) => {},
    settingClientSelected: (client) => {},
    setClientSelected: () => {},
    saveClientNotes: (notes, userId) => {},
})

export function DirectoryContextProvider({ children }) {
    const { token, id, type, isLogged } = Auth.useAuth()
    const { handleRequest, setMessage } = useRequest()

    const [clients, setClients] = useState([])
    const [clientSelected, setClientSelected] = useState(null)
    const [addNote, setAddNote] = useState(false)

    const getDirectory = async () => {
        const response = await handleRequest('get', 'coiffeur/clients', null, token)

        setClients(response?.data?.clients)
    }

    const setingClientSelected = (client) => {
        if (clientSelected == null) {
            setClientSelected(client)
        } else {
            setClientSelected(null)
        }
    }

    const createClient = async (firstName, lastName, address, phoneNumber, email) => {
        try {
            const response = await handleRequest(
                'post',
                'coiffeur/clients',
                {
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    phoneNumber: phoneNumber,
                    email: email,
                },
                token
            )

            if (response?.data?.client) {
                setMessage({ type: 'success', message: 'Le client a été créé avec succès.' })
                setClients((prevClients) => [...prevClients, response?.data?.client])
                setClientSelected(response.data.client)
            }

        } catch (error) {
            console.log('error createClient', error)
        }
    }

    const editClient = async (
        email,
        address,
        phoneNumber,
        phoneNumberChanged,
        addressChanged,
        emailChanged,
        clientId
    ) => {
        if (emailChanged) {
            try {
                const response = await handleRequest(
                    'put',
                    `coiffeur/clients/${clientId}/email`,
                    { email: email },
                    token
                )

                if (response?.data?.clients) {
                    setClients(response?.data?.clients)
                    setClientSelected(response?.data?.client)
                }
            } catch (error) {
                console.log('error addEmailToClient', error)
            }
        }
        if (phoneNumberChanged || addressChanged) {
            try {
                const response = await handleRequest(
                    'put',
                    `coiffeur/clients/${clientId}`,
                    { address: address, phoneNumber: phoneNumber },
                    token
                )

                if (response?.data?.clients) {
                    setClients(response?.data?.clients)
                    setClientSelected(response?.data?.client)
                }
            } catch (error) {
                console.log('error editClient', error)
            }
        }
    }

    const deleteClient = async (clientId) => {
        try {
            const response = await handleRequest('delete', `coiffeur/clients/${clientId}`, {}, token)

            if (response?.data?.clients) {
                setMessage({ type: 'success', message: 'Le client a été supprimé avec succès.' })
                setClients(response?.data?.clients)
                setClientSelected()
            }
        } catch (error) {
            console.log('error deleteClient', error)
        }
    }

    const searchClient = async (query, sort) => {
        try {
            if (query === '') {
                getDirectory()
            } else {
                const response = await handleRequest(
                    'get',
                    `coiffeur/clients/search?search=${query}&sort=${sort}`,
                    null,
                    token
                )

                if (response?.data?.clients) {
                    setClients(response.data.clients)
                }
            }
        } catch (error) {
            console.log('error searchClient', error)
        }
    }

    const saveClientNotes = async (notes, userId) => {
        try {
            const response = await handleRequest('put', `coiffeur/clients/${userId}/notes`, { notes: notes }, token)

            if (response?.data) {
                setMessage({ type: 'success', message: 'Note enregistrée' })
                setClients(response.data.clients)
                setClientSelected(response.data.client)
            } else {
                setMessage({ type: 'error', message: "Impossible d'enregistrer la note, réessayes plus tard" })
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (isLogged() && type === 'coiffeur') {
            getDirectory()
        }
    }, [token, id])

    return (
        <DirectoryContext.Provider
            value={{
                clients: clients,
                clientSelected: clientSelected,
                addNote: addNote,
                setAddNote: setAddNote,
                getDirectory: getDirectory,
                createClient: createClient,
                editClient: editClient,
                deleteClient: deleteClient,
                searchClient: searchClient,
                settingClientSelected: setingClientSelected,
                setClientSelected: setClientSelected,
                saveClientNotes: saveClientNotes,
            }}
        >
            {children}
        </DirectoryContext.Provider>
    )
}

export const useDirectory = () => useContext(DirectoryContext)
