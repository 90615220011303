import React, { createContext, useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { Auth, Request } from '../..'
import { Agenda } from '..'

import { BOOKING_INITIAL_VALUE } from './utils'
import { socket } from '../../../api/api'

/* eslint no-unused-vars: 0 */
const BookingContext = createContext({
    bookingSelected: Object || null,
    step: String || null,
    modal: String || null,
    bookingsWaiting: Array,
    bookingsList: Array,
    getBookingWaitings: () => {},
    createBooking: () => {},
    getBookingById: (id) => {},
    editBooking: () => {},
    selectBooking: (value, step) => {},
    setModal: (value) => {},
    searchBookingByClientName: (name) => {},
    cancelBooking: (bookingId, reason) => {},
    moveBooking: (bookingId, reason, date, newDate, newTimes) => {},
    commentBooking: (bookingId, value, date) => {},
    acceptBooking: (bookingId, date) => {},
    getWaitings: () => {},
    addBookingManually: (client, address, prestation, date, from, note, totalPrice, priceVariable, totalDuration) => {},
    addIndisponibility: (allDay, dateSelected, startTime, endTime, dateEnd) => {},
})

export function BookingContextProvider({ children }) {
    const { handleRequest, setMessage } = Request.useRequest()
    const { id, token, isLogged } = Auth.useAuth()
    const { daySelected, setDaySelected } = Agenda.useView()
    const { refreshMonth } = Agenda.useMonth()

    const [bookingSelected, setBookingSelected] = useState()
    const [step, setStep] = useState()
    const [bookingsWaiting, setBookingsWaiting] = useState([])
    const [bookingsList, setBookingsList] = useState({})
    const [modal, setModal] = useState()

    const getWaitings = async () => {
        const response = await handleRequest('get', `coiffeur/bookings/${id}/waiting`, null, token)

        if (response?.data) {
            setBookingsWaiting(response.data?.bookings)
        }
    }

    const selectBooking = (value) => {
        if (value === 'initial') {
            const initialValue = { date: daySelected || dayjs().format('YYYY-MM-DD'), ...BOOKING_INITIAL_VALUE }
            setBookingSelected(initialValue)
        } else {
            setBookingSelected(value)
        }
    }

    const searchBookingByClientName = async (search) => {
        const response = await handleRequest(
            'get',
            `coiffeur/bookings/${id}/clients/search?search=${search}`,
            null,
            token
        )

        setBookingsList(response.data)
    }

    const cancelBooking = async (bookingId, reason) => {
        try {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                {
                    state: 'cancel',
                    reason: reason,
                },
                token
            )

            if (response?.data) {
                refreshMonth(dayjs(response?.data?.date).format('YYYY-MM'))
                setMessage({ type: 'success', message: 'Le rendez-vous a été annulé' })
                setBookingSelected()
                setModal()
                getWaitings()
                return response.data
            }
        } catch (error) {
            console.log('error cancelBooking', error)
        }
    }

    const moveBooking = async (bookingId, reason, date, newDate, newTimes) => {
        try {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                {
                    state: 'move',
                    reason: reason,
                    date: date,
                    newDate: newDate,
                    newTimes: newTimes,
                },
                token
            )

            if (response?.data) {
                refreshMonth(dayjs(response?.data?.newDay?.date).format('YYYY-MM'))
                setMessage({
                    type: 'success',
                    noIcon: true,
                    message: 'Le rendez-vous a été reporté veuillez attendre la confirmation du client',
                })
                setBookingSelected()
                setModal()
                getWaitings()
                return response.data.newDay
            }
        } catch (error) {
            console.log('error moveBooking', error)
        }
    }

    const commentBooking = async (bookingId, value) => {
        try {
            const response = await handleRequest(
                'put',
                `booking/${id}/${bookingSelected?._client?._id}/${bookingId}/note`,
                {
                    note: [{ value: value, date: dayjs().toISOString(), from: 'pro' }],
                },
                token
            )

            if (response?.data) {
                console.log(response.data)
                return response.data
            }
        } catch (error) {
            console.log('error commentBooking', error)
        }
    }

    const acceptBooking = async (bookingId, date) => {
        try {
            const response = await handleRequest(
                'put',
                `coiffeur/bookings/${id}/${bookingId}`,
                {
                    state: 'booked',
                    date: date,
                },
                token
            )

            setBookingSelected(response?.data.booking)

            if (response?.data) {
                setMessage({ type: 'success', message: 'Le rendez-vous a été confirmé et ajouté au calendrier' })
                setBookingSelected()
                setModal()
                refreshMonth(dayjs(response?.data?.date).format('YYYY-MM'))
                getWaitings()
                setDaySelected(date)
                return response.data
            }
        } catch (error) {
            console.log('error acceptBooking', error)
        }
    }

    const addBookingManually = async (
        client,
        address,
        prestation,
        date,
        from,
        note,
        totalPrice,
        priceVariable,
        totalDuration
    ) => {
        try {
            const response = await handleRequest(
                'post',
                `coiffeur/bookings/${id}/v2`,
                {
                    client: client,
                    address: address,
                    prestation: prestation,
                    date: dayjs(date).format('YYYY-MM-DD'),
                    from: from,
                    note: note,
                    price: totalPrice,
                    priceVariable: priceVariable,
                    duration: totalDuration,
                    to: from + totalDuration / 1000,
                },
                token
            )
            if (response?.data) {
                setMessage({
                    type: 'success',
                    message: 'Le rendez-vous a été confirmé et ajouté au calendrier',
                })
                refreshMonth(dayjs(response?.data?.date).format('YYYY-MM'))
                getWaitings()
                setBookingSelected()
                setDaySelected(date)

                return response.data
            }
        } catch (error) {
            console.log('error addBookingManually', error)
        }
    }

    const addIndisponibility = async (allDay, dateSelected, startTime, endTime, dateEnd) => {
        try {
            const response = await handleRequest(
                'post',
                `coiffeur/bookings/${id}/private/v2`,
                {
                    date: dateSelected,
                    from: startTime,
                    to: !allDay ? endTime : null,
                    dateEnd: allDay ? dateEnd : dateSelected,
                    allDay: allDay,
                },
                token
            )
            if (response?.data) {
                setMessage({
                    type: 'success',
                    message: "L'indisponibilité a été confirmé et ajouté au calendrier",
                })
                refreshMonth(dayjs(response?.data?.date).format('YYYY-MM'))
                getWaitings()
                setBookingSelected()
                setDaySelected(dateSelected)

                return response.data
            }
        } catch (error) {
            console.log('error addIndisponibility', error)
        }
    }


    return (
        <BookingContext.Provider
            value={{
                bookingSelected: bookingSelected,
                step: step,
                modal: modal,
                bookingsWaiting: bookingsWaiting,
                bookingsList: bookingsList,
                selectBooking: selectBooking,
                searchBookingByClientName: searchBookingByClientName,
                cancelBooking: cancelBooking,
                moveBooking: moveBooking,
                commentBooking: commentBooking,
                acceptBooking: acceptBooking,
                getWaitings: getWaitings,
                setStep: setStep,
                setModal: setModal,
                addBookingManually: addBookingManually,
                addIndisponibility: addIndisponibility,
            }}
        >
            {children}
        </BookingContext.Provider>
    )
}

export const useBooking = () => useContext(BookingContext)
