import React from 'react'
import { Button, Input } from '.'
import { ProContext } from '../../contexts'

const ClientDirectory = () => {
    const { searchClient, setClientSelected } = ProContext.Directory.useDirectory()

    return (
        <div className="mx-4">
            <p className="font-semibold text-[20px] my-5 text-center">Répertoire de clients</p>
            <Button.primary title={'Créer un client'} onClick={() => setClientSelected({})} />
            <div className="my-5">
                <Input.search
                    placeholder={'Rechercher'}
                    onSearch={(value) => {
                        searchClient(value, 1)
                    }}
                />
            </div>
        </div>
    )
}

export { ClientDirectory }
