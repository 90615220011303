import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Cards, Modal, Text } from '../../components/atoms'
import { Return, FloatingButton, Profil } from '../../components/molecules'
import { ClientContext } from '../../contexts'

const Export = () => {
    const navigate = useNavigate()
    const { profil, getProfilById, loading } = ClientContext.Reservation.useReservation()

    const [selectedImage, setSelectedImage] = useState()

    const { coiffeurId } = useParams()

    useEffect(() => {
        const getProfile = async () => {
            await getProfilById(coiffeurId)
        }
        if (!profil || (profil.length === 0 && coiffeurId)) {
            getProfile()
        }
    }, [coiffeurId])

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        if (container) {
            container.scrollTo(0, 0)
        }
    }, [])

    if (loading) {
        return <Text.normal className="py-10 text-center">Chargement...</Text.normal>
    }

    return (
        <div className="relative">
            <Return.Favorites className="p-4" isFavorite={false} onClick={() => navigate('/')} />
            <div className="py-4 mt-4 mb-12">
                {profil ? (
                    <div>
                        <Cards.ProProfil
                            key={profil?._id}
                            onClick={() => navigate(`/prestations/${profil?._id}`)}
                            name={`${profil?._profil.firstName} ${profil?._profil.lastName}`}
                            experience={profil?._profil.yearsExperience}
                            ppCoiffeur={profil?._profil.avatar?.thumbnailUrl}
                        />
                        <Profil.Category categories={profil?._profil.category} />
                        <Profil.Biography>{profil?._profil.description}</Profil.Biography>
                        <Profil.Gallery
                            images={profil?._profil._photos}
                            onImageClick={(image) => setSelectedImage(image)}
                        />
                        {selectedImage && (
                            <Modal.Image selectedImage={selectedImage} closeModal={() => setSelectedImage()} />
                        )}
                        <Profil.Contact email={profil?.email} phone={profil?._profil.phoneNumber} />
                        <Profil.Specialities specialities={profil?._profil._specialities} />
                        <Profil.Schedule proId={profil._id} />
                        {!selectedImage && (
                            <FloatingButton.Primary
                                title={'Prendre RDV'}
                                onClick={() => navigate(`/prestations/${profil?._id}`)}
                            />
                        )}
                    </div>
                ) : (
                    <Text.normal className="py-10 text-center">Profil inconnu</Text.normal>
                )}
            </div>
        </div>
    )
}

export default Export
