import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Minus, Plus, ChevronDown } from 'lucide-react'

import { Button, Text } from '../atoms'
import { Prestations, Tab } from '../molecules'
import { Client } from '../templates'

import { ClientContext, Auth, Address } from '../../contexts'

import dayjs from 'dayjs'

const useIntersectionObserver = (callback, reverseCallback, options) => {
    const ref = useRef(null)

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                callback()
            } else {
                reverseCallback()
            }
        }, options)

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [ref, options, callback])

    return ref
}

export const Selecting = ({ prestations, to, selectedType, setSelectedType }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const { isLogged } = Auth.useAuth()

    const { setStep, step, professionalFound, selectedAddress, coordinates } = Address.useAddress()
    const { profil, getDays, prestationsSelected, addPrestation, removePrestation } =
        ClientContext.Reservation.useReservation()

    const [isInTheZone, setIsInTheZone] = useState(true)
    const [needAddress, setNeedAddress] = useState(false)

    const handleBook = async () => {
        await getDays(dayjs().format('YYYY-MM-DD'))
        if (isLogged()) {
            navigate('/client/reservation')
        } else {
            navigate('/reservation')
        }
    }

    useEffect(() => {
        if (!isLogged()) {
            if (location.pathname.indexOf('/client') !== -1) {
                navigate(location.pathname.split('client/pro')[1])
            }
        }

        if (professionalFound.length > 0 && selectedAddress) {
            if (professionalFound.filter((pro) => pro._id === profil._id).length === 0) setIsInTheZone(false)
            else setIsInTheZone(true)
        } else {
            setNeedAddress(true)
        }
    }, [])

    useEffect(() => {
        if (professionalFound.length > 0 && selectedAddress && coordinates.latitude && coordinates.longitude) {
            if (professionalFound.filter((pro) => pro._id === profil._id).length === 0) setIsInTheZone(false)
            else setIsInTheZone(true)
            setNeedAddress(false)
        } else {
            setNeedAddress(true)
        }
    }, [professionalFound])

    const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
    }

    const [showScrollButton, setShowScrollButton] = useState(true)

    const lastItemRef = useIntersectionObserver(
        () => {
            setShowScrollButton(false)
        },
        () => {
            setShowScrollButton(true)
        },
        options
    )

    useEffect(() => {
        if (step === 'results') setStep('home')
    }, [step])

    return (
        <Client.AddressFlow.Prestations>
            <Tab.Prestations selectedType={selectedType} handleTabClick={(value) => setSelectedType(value)} />
            {prestations.length > 0 &&
                prestations.filter((prestation) => prestation.type === selectedType && !prestation.forfait).length >
                    0 && (
                    <>
                        <Prestations.List
                            intitalPrestations={prestations.filter(
                                (prestation) => prestation.type === selectedType && !prestation.forfait
                            )}
                            title={'Prestations'}
                            to={to}
                            type={selectedType}
                        />
                    </>
                )}
            {prestations.length > 0 &&
                prestations.filter((prestation) => prestation.type === selectedType && prestation.forfait).length >
                    0 && (
                    <>
                        <Prestations.List
                            intitalPrestations={prestations.filter(
                                (prestation) => prestation.type === selectedType && prestation.forfait
                            )}
                            title={'Forfaits'}
                            to={to}
                            type={selectedType}
                        />
                    </>
                )}
            {!needAddress ? (
                isInTheZone ? (
                    <div className="grid mt-[24px] gap-[10px]">
                        <div
                            onClick={() => {
                                const container = isLogged()
                                    ? document.getElementsByClassName('h-logged')[0]
                                    : document.getElementsByClassName('h-unlogged')[0]
                                container.scrollTo({ top: container.scrollHeight, behavior: 'smooth' })
                            }}
                            className={`transition-all fixed right-0 bottom-[19%] rounded-l-[20px] p-[8px] z-[40] ${
                                showScrollButton ? 'block bg-v2_purple opacity-100' : 'hidden bg-transparent opacity-0'
                            }`}
                        >
                            <ChevronDown color="#DFCDEA" size={30} />
                        </div>
                        <p className="font-semibold text-[18px] leading-[30px] tracking-tighter mx-[20px] text-v2_purple">
                            Prestations sélectionnées
                        </p>
                        <div className="relative bg-background_light">
                            <div className="bg-background_light w-[200%] h-full absolute left-[-10%] z-[0]"></div>
                            <div className="z-10 relative pt-[20px] pb-[22px]">
                                <div className="px-[20px] mb-[16px]">
                                    {prestationsSelected.map((prestation) => (
                                        <div key={prestation._id} className="flex justify-between w-full">
                                            <Text.normal>{prestation.name}</Text.normal>
                                            <div className="flex items-center">
                                                <Minus
                                                    className="mr-4 cursor-pointer"
                                                    size={12}
                                                    onClick={() => removePrestation(prestation._id)}
                                                />
                                                <Text.normal>{prestation.counter}</Text.normal>
                                                <Plus
                                                    className="ml-4 cursor-pointer"
                                                    size={12}
                                                    onClick={() => addPrestation(prestation)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div ref={lastItemRef}>
                                    <Button.primary
                                        title={'Réserver'}
                                        onClick={handleBook}
                                        disabled={prestationsSelected.length === 0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="grid gap-2 mx-4 my-10">
                        <p className="text-[16px] font-medium leading-5 text-[#000] text-center">
                            {profil._profil?.firstName} {profil._profil?.lastName} ne se déplace pas chez vous
                        </p>
                        <Button.secondary title="Rechercher un professionnel" onClick={() => navigate('/')} />
                    </div>
                )
            ) : (
                <div className="mx-4 my-10">
                    <Button.primary
                        title={'Renseigner mon adresse'}
                        onClick={() => {
                            setStep('address')
                        }}
                    />
                </div>
            )}
        </Client.AddressFlow.Prestations>
    )
}
