import React, { useState } from 'react'
import { Text, Button, Loader } from '../../../atoms'
import { Return } from '../../../molecules'
import { Reservation } from '../../../molecules/Client'
import { Client } from '../../../organisms'
import { ClientContext, Address, Auth } from '../../../../contexts'
import { useNavigate } from 'react-router-dom'

export default function Recap({ goToStep }) {
    const navigate = useNavigate()
    const { prestationsSelected, profil, selectedReservation, totalPrice, totalTime, priceVariable, bookSchedule } =
        ClientContext.Reservation.useReservation()
    const { selectedAddress, coordinates } = Address.useAddress()
    const { isLogged } = Auth.useAuth()

    const [loading, setLoading] = useState(false)

    const handleSignupClick = () => {
        navigate('/signup', { state: { source: '/reservation' } })
    }

    const handleLoginClick = () => {
        navigate('/login', { state: { source: '/reservation' } })
    }

    return (
        <div>
            <Return.Primary className="px-4" onClick={() => goToStep(1)} />
            {loading ? (
                <div className="flex flex-col items-center justify-center w-full">
                    <Loader.Primary />
                    <Text.h4 color={'v2_purple'} align={'center'}>
                        Réservation en cours
                    </Text.h4>
                </div>
            ) : (
                <>
                    {isLogged() ? (
                        <div className="px-4">
                            <div className="flex justify-center mt-6">
                                <Text.h5 className="font-semibold text-center" color={'v2_purple'}>
                                    Récapitulatif de prestations sélectionnées
                                </Text.h5>
                            </div>
                            <Client.Reservation.Prestations.PrestationsCard prestations={prestationsSelected} />
                            <Reservation.List type={'date'} selectedReservation={selectedReservation} />
                            <Reservation.List type={'price'} priceVariable={priceVariable} totalPrice={totalPrice} />
                            <Reservation.List type={'time'} totalTime={totalTime} />
                            <Reservation.List
                                type={'address'}
                                selectedAddress={
                                    selectedReservation.horaire.place
                                        ? `${selectedReservation.horaire.place.name} : ${selectedReservation.horaire.place.address.formatted}`
                                        : selectedAddress
                                }
                            />
                            <div className="py-8">
                                <Button.primary
                                    onClick={async () => {
                                        setLoading(true)
                                        const address = selectedReservation.horaire.place
                                            ? {
                                                  name: selectedReservation.horaire.place.name,
                                                  ...selectedReservation.horaire.place.address,
                                              }
                                            : {
                                                  formatted: selectedAddress,
                                                  lat: coordinates.latitude,
                                                  lon: coordinates.longitude,
                                              }

                                        const response = await bookSchedule(address)
                                        if (!response.error) {
                                            goToStep(4)
                                            setLoading(false)
                                        }
                                    }}
                                    title={'Continuer'}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="mt-4">
                                <Client.Reservation.Prestations.Prestations prestations={prestationsSelected} />
                                <div onClick={() => navigate(`/prestations/${profil._id}`)}>
                                    <Text.small className="font-medium text-center underline text-v2_purple">
                                        Ajouter une prestation
                                    </Text.small>
                                </div>
                            </div>
                            <Client.Reservation.Prestations.Date
                                goToStep={goToStep}
                                selectedReservation={selectedReservation}
                            />
                            <div className="flex flex-col px-4 py-6 space-y-8">
                                <Button.primary title={"S'inscrire"} onClick={handleSignupClick} />
                                <div className="flex flex-col space-y-2">
                                    <Text.small color={'black'} className="font-medium text-center">
                                        Vous avez déjà un compte ?
                                    </Text.small>
                                    <Button.secondary title={'Se connecter'} onClick={handleLoginClick} />
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    )
}
