import React, { useState } from 'react'

import { Profesionnal } from '../../components/organisms'
import { ProContext } from '../../contexts'

const Profil = () => {
    const { toUpdate } = ProContext.Profil.useProfilPro()

    const [currentPage, setCurrentPage] = useState('')

    return (
        <div className="grid w-full gap-[30px]">
            {currentPage === 'changePwd' ? (
                <Profesionnal.Profile.ChangePwd onChange={(value) => setCurrentPage(value)} />
            ) : currentPage === 'deleteAccount' ? (
                <Profesionnal.Profile.DeleteAccount onChange={(value) => setCurrentPage(value)} />
            ) : (
                <>
                    {!toUpdate ? (
                        <>
                            <Profesionnal.Profile.ProfileCategories />
                            <Profesionnal.Profile.FooterBtns onChange={(value) => setCurrentPage(value)} />
                        </>
                    ) : (
                        <Profesionnal.Profile.UpdateProfile categoryToUpdate={toUpdate} />
                    )}
                </>
            )}
        </div>
    )
}

export default Profil
