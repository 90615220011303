import React, { createContext, useContext, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'

import * as AgendaDB from './AgendaDB'
import { Auth, Request } from '../..'

dayjs.extend(weekOfYear)
dayjs.extend(isoWeek)

/* eslint no-unused-vars: 0 */
const WeekContext = createContext({
    database: Object || null,
    nextWeek: Object || null,
    currentWeek: Object || null,
    previousWeek: Object || null,
    needSynchronisation: Boolean,
    getWeek: (yearSelected, weekSelected) => {},
})

export function WeekContextProvider({ children }) {
    const { handleRequest } = Request.useRequest()
    const { token, id } = Auth.useAuth()

    const [agendaDatabase, setAgendaDatabase] = useState(null)

    useEffect(() => {
        AgendaDB.openIndexedDB().then((database) => setAgendaDatabase(database))
    }, [])

    const [currentYear, setCurrentYear] = useState()
    const [currentWeekNumber, setCurrentWeekNumber] = useState()
    const [previousWeek, setPreviousWeek] = useState()
    const [currentWeek, setCurrentWeek] = useState()
    const [nextWeek, setNextWeek] = useState()

    const getWeekDates = (year, weekNumber) => {
        const startOfWeek = dayjs().set('year', year).isoWeek(weekNumber).startOf('isoWeek')

        const weekDates = []

        for (let i = 0; i < 7; i++) {
            weekDates.push(startOfWeek.add(i, 'day').format('YYYY-MM-DD'))
        }

        return weekDates
    }

    const getMonthFromCache = async (key) => {
        if (!('indexedDB' in window)) return null
        return await AgendaDB.getOneMonth(agendaDatabase, key)
    }

    const setWeek = (variableToChange, value) => {
        switch (variableToChange) {
            case 'current':
                setCurrentWeek(value)
                break
            case 'previous':
                setPreviousWeek(value)
                break
            case 'next':
                setNextWeek(value)
                break
        }
    }

    const getMonth = async (key) => {
        let data = await getMonthFromCache(key)

        if (!data) {
            const response = await handleRequest('get', `coiffeur/days/${id}/${key}-01/month`, null, token)

            data = response.data.days.reduce((days, value) => {
                const { date, ...data } = value
                days[date] = data
                return days
            }, {})

            await AgendaDB.upsertMonthInStore(agendaDatabase, {
                monthKey: key,
                days: data,
                refreshAt: dayjs().toISOString(),
            })

            data = { monthKey: key, days: data }
        }

        return data
    }

    const getWeekPrivate = async (yearNumber, weekNumber, variableToChange) => {
        const week = []
        const weeksDates = getWeekDates(yearNumber, weekNumber)

        let key = dayjs(weeksDates[0]).format('YYYY-MM')
        let currentMonth = await getMonth(key)

        for (let i = 0; i < weeksDates.length; i++) {
            const date = weeksDates[i]
            key = dayjs(date).format('YYYY-MM')

            if (currentMonth.monthKey !== key) {
                currentMonth = await getMonth(key)
            }

            week.push({ date: date, ...currentMonth.days[date] })
        }

        setWeek(variableToChange, week)
    }

    const getWeek = async (yearNumber, weekNumber) => {
        if (previousWeek && nextWeek && yearNumber === currentYear) {
            if (currentWeekNumber - 1 === weekNumber) {
                setCurrentWeek(previousWeek)
                setNextWeek(currentWeek)

                const previousWeekNumber = weekNumber - 1
                await getWeekPrivate(yearNumber, previousWeekNumber, 'previous')

                return
            }

            if (currentWeekNumber + 1 === weekNumber) {
                setCurrentWeek(nextWeek)
                setPreviousWeek(currentWeek)

                const nextWeekNumber = weekNumber + 1
                await getWeekPrivate(yearNumber, nextWeekNumber, 'next')

                return
            }
        }

        await getWeekPrivate(yearNumber, weekNumber, 'current')

        const nextWeekNumber = weekNumber + 1
        const previousWeekNumber = weekNumber - 1
        await Promise.all([
            getWeekPrivate(yearNumber, nextWeekNumber, 'next'),
            getWeekPrivate(yearNumber, previousWeekNumber, 'previous'),
        ])

        setCurrentWeekNumber(weekNumber)
        setCurrentYear(yearNumber)
        return
    }

    return (
        <WeekContext.Provider
            value={{
                database: agendaDatabase,
                previousWeek: previousWeek,
                currentWeek: currentWeek,
                nextWeek: nextWeek,
                getWeek: getWeek,
                setCurrentWeek: setCurrentWeek,
            }}
        >
            {children}
        </WeekContext.Provider>
    )
}

export const useWeek = () => useContext(WeekContext)
