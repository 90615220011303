import React, { useState, useEffect } from 'react'
import {
    Pencil,
    Copy,
    Split,
    Plus,
    User,
    Mail,
    Phone,
    MapPin,
    BookUser,
    GripVertical,
    Trash2,
    ChevronDown,
    Check,
    ArrowLeft,
} from 'lucide-react'
import dayjs from 'dayjs'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import Lottie from 'react-lottie'

import { Button, Input, Accordion, Cards, Text, Icon, Horaires, Map, Modal } from '../../atoms'
import { Pro } from '../../molecules'
import { Caption } from '../../molecules/Client/Reservation'
import { Images } from '../../../components/organisms'
import { Pro as ProTemplates } from '../../templates'

import * as animationData from '../../../assets/lottie/hourglass.json'
import AvatarDefault from '../../../assets/avatar/Girl.svg'
import noCover from '../../../assets/banner/image-up.png'

import { ProContext, Auth, Images as ImagesContext, Address } from '../../../contexts'

const ProfileCategories = () => {
    const {
        companyName,
        firstName,
        lastName,
        yearsExperience,
        category,
        description,
        specialities,
        rayon,
        address,
        phoneNumber,
        photos,
        paymentMode,
        isVisible,
        siret,
        avatar,
        cover,
        personnalAddress,
        setToUpdate,
        setPhotos,
        handleSaveAttribute,
        handleSaveAttributeV2,
        handleSetAttribute,
        setPaymentMode,
    } = ProContext.Profil.useProfilPro()
    const { email, id } = Auth.useAuth()
    const { images, getImages, handleDeleteImage } = ImagesContext.useImages()
    const schedule = ProContext.Schedule.useSchedule()
    const place = ProContext.Place.usePlace()
    const { setStep } = Address.useAddress()

    const [toggleValue, setToggleValue] = useState(isVisible)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setToggleValue(isVisible)
    }, [isVisible])

    const [photoModal, setPhotoModal] = useState(false)
    const [coverModal, setCoverModal] = useState(false)
    const [showAllPhotos, setShowAllPhotos] = useState(false)
    const photosToShow =
        showAllPhotos && Array.isArray(photos) ? photos : Array.isArray(photos) ? photos.slice(0, 4) : []

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const reorderedPhotos = Array.from(photos)
        const [reorderedPhoto] = reorderedPhotos.splice(result.source.index, 1)
        reorderedPhotos.splice(result.destination.index, 0, reorderedPhoto)

        setPhotos(reorderedPhotos)
    }

    const deletePhoto = async (id) => {
        handleDeleteImage(id)
        const updatedPhotos = photos.filter((photo) => photo._id !== id)
        setPhotos(updatedPhotos)
        setShowAllPhotos(updatedPhotos.length <= 4 ? updatedPhotos : updatedPhotos.slice(0, 4))
        await handleSaveAttribute('photos', updatedPhotos)
    }

    const [newPaymentMode, setNewPaymentMode] = useState([
        { name: 'Chèque', accepted: false },
        { name: 'Espèces', accepted: false },
        { name: 'Carte bancaire', accepted: false },
        { name: 'Virement', accepted: false },
    ])

    const [onCopyLink, setOnCopyLink] = useState(false)

    const copyLink = () => {
        navigator.clipboard
            .writeText(`https://ilea-app.com/client/pro/profil/${id}`)
            .then(() => {
                setOnCopyLink(true)
                setTimeout(() => {
                    setOnCopyLink(false)
                }, 3000)
            })
            .catch((error) => console.log(error))
    }

    const [openHoraireToast, setOpenHoraireToast] = useState(false)
    useEffect(() => {
        setOpenHoraireToast(schedule.loading)
    }, [schedule.loading])

    console.log(cover)

    return (
        <div className="grid w-full gap-[24px]">
            <ProTemplates.Address.Places></ProTemplates.Address.Places>
            <div className="grid gap-[16px] w-full px-[34px] py-[16px] rounded-[30px] shadow-basic">
                <div className="flex justify-end w-full">
                    <div className="flex space-x-1" onClick={() => setToUpdate('profile')}>
                        <span>Modifier</span>
                        <Pencil size={20} />
                    </div>
                </div>
                <div className="grid w-full gap-[34px]">
                    <div className="flex space-x-[24px]">
                        <div
                            style={{
                                backgroundImage: avatar ? `url(${avatar.thumbnailUrl})` : `url(${AvatarDefault})`,
                                backgroundSize: 'contain',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                            }}
                            className="size-16"
                        ></div>
                        <div className="grid gap-[8px]">
                            <p className="text-[18px] font-bold leading-[32px] tracking-[-0.36px]">{companyName}</p>

                            <div className="flex flex-row items-center">
                                <Input.Toggle
                                    value={toggleValue}
                                    setValue={() => {
                                        const newValue = !toggleValue
                                        setToggleValue(newValue)
                                        handleSaveAttributeV2('isVisible', newValue)
                                    }}
                                />
                                <p className="text-[14px] font-medium ml-4">
                                    {toggleValue ? 'Profil Visible' : 'Profil Invisible'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex space-x-[8px] items-center">
                            <div onClick={copyLink}>
                                <Copy size={30} />
                            </div>
                            <p className="text-[12px] leading-[20px] font-medium">Lien de mon profil</p>
                        </div>
                        {onCopyLink ? (
                            <div className="flex flex-row items-center">
                                <p className="text-[12px] leading-[20px] font-medium mr-2">Lien copié</p>
                                <Check color="#4AF93B" size={20} />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <Accordion.Profil title="À propos" toNavigate={() => setToUpdate('apropos')}>
                <div className="grid gap-[18px]">
                    <div className="grid gap-1">
                        <p className="text-[16px] font-medium">Categories</p>
                        <div className="flex flex-row flex-wrap w-full item-center">
                            {category &&
                                category.map((i, index) => {
                                    return (
                                        <div key={index} className="my-1 mr-1">
                                            <Cards.ProCategorie title={i} />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>

                    <div className="grid gap-[4px]">
                        <p className="text-[16px] font-medium">Années d’experience</p>
                        <p className="text-[16px]">{yearsExperience} années</p>
                    </div>

                    <div className="grid gap-[4px]">
                        <p className="text-[16px] font-medium">Biographie</p>
                        <p className="text-[16px]">
                            {description} <p className="text-[16px] font-medium text-v2_purple">Voir plus</p>
                        </p>
                    </div>

                    <div className="grid gap-[4px]">
                        <p className="text-[16px] font-medium">Mes spécialités</p>
                        <div className="flex flex-row flex-wrap items-center w-full">
                            {Array.isArray(specialities) &&
                                specialities.map((i, index) => {
                                    return (
                                        <div key={index} className="my-1 mr-1 h-fit">
                                            <Cards.ProSpecialities title={i.name} />
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>
            </Accordion.Profil>
            <Accordion.Profil title="Modes de paiement" noEdit>
                {paymentMode && paymentMode.length === 4
                    ? paymentMode.map((i, index) => (
                          <div key={index} className="grid gap-[20px] mb-12">
                              <Input.Checkbox
                                  label={i.name}
                                  value={i.accepted}
                                  setValue={() => {
                                      const updatedPaymentMode = paymentMode.map((mode, idx) => {
                                          if (idx === index) {
                                              return { ...mode, accepted: !mode.accepted }
                                          }
                                          return mode
                                      })

                                      setPaymentMode(updatedPaymentMode)
                                      handleSaveAttributeV2('paymentMode', updatedPaymentMode)
                                  }}
                              />
                          </div>
                      ))
                    : newPaymentMode.map((i, index) => (
                          <div key={index} className="grid gap-[20px] mb-12">
                              <Input.Checkbox
                                  label={i.name}
                                  value={i.accepted}
                                  setValue={() => {
                                      const updatedPaymentMode = newPaymentMode.map((mode, idx) => {
                                          if (idx === index) {
                                              return { ...mode, accepted: !mode.accepted }
                                          }
                                          return mode
                                      })

                                      setNewPaymentMode(updatedPaymentMode)
                                      handleSaveAttributeV2('paymentMode', updatedPaymentMode)
                                  }}
                              />
                          </div>
                      ))}
            </Accordion.Profil>
            <Accordion.Profil title="Déplacement" toNavigate={() => setToUpdate('deplacement')}>
                <div className="grid gap-[16px]">
                    <div className="grid gap-[4px]">
                        <p className="text-[14px] leading-[21px] font-semibold">Rayon de déplacement</p>
                        <div className="flex space-x-[12px] overflow-hidden">
                            <MapPin size={20} />
                            <p className="text-[14px] leading-[19.6px] tracking-[-0.14px] overflow-hidden truncate">
                                {address?.formatted}
                            </p>
                        </div>
                        <div className="flex space-x-[12px]">
                            <Split size={20} />
                            <p className="text-[14px] leading-[19.6px] tracking-[-0.14px] overflow-hidden truncate">
                                {rayon} km
                            </p>
                        </div>
                    </div>
                    <div className="w-full h-fit">
                        {address?.formatted && rayon ? <Map.Rayon /> : <p>Chargement de la carte...</p>}
                    </div>
                    <div className="grid gap-[4px]">
                        <p className="text-[14px] leading-[21px] font-semibold">Lieux de travail</p>
                        <Caption.AtPlace />
                    </div>
                    {place &&
                        place?.list &&
                        place?.list?.map((i, index) => {
                            if (i._id) {
                                return (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            place.setSelected(i)
                                            place.setStep('edit')
                                            setStep('address')
                                        }}
                                    >
                                        <div className="py-[12px] px-[22px] border rounded-full bg-v2_white border-v2_purple">
                                            {i.address.formatted}
                                        </div>
                                    </div>
                                )
                            }
                        })}
                    <div
                        className=" flex justify-between py-[12px] px-[22px] border rounded-full bg-v2_white border-v2_purple"
                        onClick={() => {
                            setToUpdate('deplacement')
                            place.setSelected({ name: '', address: {} })
                            place.setStep('create')
                            setStep('address')
                        }}
                    >
                        <p className="text-[#979797]">Ajouter une adresse</p>
                        <Plus color="#72517E" size={20} />
                    </div>
                </div>
            </Accordion.Profil>
            <Accordion.Profil title="Horaires" toNavigate={() => setToUpdate('horaires')}>
                <Pro.Profil.Schedule />
            </Accordion.Profil>
            <Accordion.Profil title="Mes coordonnées" toNavigate={() => setToUpdate('coords')}>
                <div>
                    <div className="my-[8px]">
                        <p className="text-[16px] font-medium">Nom et prénom</p>
                        <div className="flex flex-row items-center mt-[4px]">
                            <User color="#72517E" size={30} />
                            <p className="text-[16px] ml-[12px]">
                                {firstName} {lastName}
                            </p>
                        </div>
                    </div>
                    <div className="my-[8px]">
                        <p className="text-[16px] font-medium">Email</p>
                        <div className="flex flex-row items-center mt-[4px]">
                            <Mail color="#72517E" size={30} />
                            <p className="text-[16px] ml-[12px]">{email}</p>
                        </div>
                    </div>
                    <div className="my-[8px]">
                        <p className="text-[16px] font-medium">Téléphone</p>
                        <div className="flex flex-row items-center mt-[4px]">
                            <Phone color="#72517E" size={30} />
                            <p className="text-[16px] ml-[12px]">{phoneNumber.phone}</p>
                        </div>
                    </div>
                    <div className="my-[8px]">
                        <p className="text-[16px] font-medium">Adresse</p>
                        <div className="flex flex-row items-center mt-[4px]">
                            <MapPin color="#72517E" size={30} />
                            <p className="text-[16px] ml-[12px]">{personnalAddress?.formatted}</p>
                        </div>
                    </div>
                    <div className="my-[8px]">
                        <p className="text-[16px] font-medium">Siret</p>
                        <div className="flex flex-row items-center mt-[4px]">
                            <BookUser color="#72517E" size={30} />
                            <p className="text-[16px] ml-[12px]">{siret ? siret : 'Aucun SIRET renseigné'}</p>
                        </div>
                    </div>
                </div>
            </Accordion.Profil>
            <Accordion.Profil title="Galerie Photo" noEdit>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="photos-gallery" direction="horizontal">
                        {(provided) => (
                            <div
                                className="flex flex-row flex-wrap my-4"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {photosToShow &&
                                    photosToShow.map((photo, index) => (
                                        <Draggable key={photo._id} draggableId={photo._id} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="size-[150px] bg-v2_lightpurple m-1 relative"
                                                >
                                                    <img
                                                        src={photo.url}
                                                        alt={`Photo ${photo._id}`}
                                                        className="object-cover w-full h-full"
                                                    />
                                                    <div className="absolute top-2 left-2">
                                                        <GripVertical color="white" size={28} />
                                                    </div>
                                                    <div
                                                        className="absolute p-2 bg-white rounded-full bottom-2 right-2"
                                                        onClick={() => deletePhoto(photo._id)}
                                                    >
                                                        <Trash2 color="black" size={20} />
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {photos.length > 4 && (
                    <div
                        className="flex flex-row items-center cursor-pointer"
                        onClick={() => setShowAllPhotos(!showAllPhotos)}
                    >
                        <p className="text-[16px] font-semibold mr-2 my-2 underline">
                            {showAllPhotos ? 'Voir moins' : 'Voir plus'}
                        </p>
                        <div className={showAllPhotos ? 'rotate-180' : ''}>
                            <ChevronDown color="#000" size={20} />
                        </div>
                    </div>
                )}

                <div className="w-full mt-4">
                    <Button.secondary title={'Ajouter plus de photo'} onClick={() => setPhotoModal(!photoModal)} />
                </div>

                {photoModal && (
                    <Images.Upload
                        title="Ajouter une photo"
                        setIsOpen={() => setPhotoModal(false)}
                        isOpen={photoModal}
                        afterUpload={async (value) => {
                            const newPhotos = [...images.current]
                            newPhotos.push(value)

                            await handleSaveAttribute('photos', newPhotos)
                            await getImages()
                            setPhotoModal(false)
                            setLoading(false)
                        }}
                        loading={loading}
                        setLoading={setLoading}
                    />
                )}
            </Accordion.Profil>
            <Accordion.Profil title="Photo de couverture" noEdit>
                <div>
                    <div className="w-full mt-4 mb-[30px]">
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                backgroundImage: `url(${cover ? cover.thumbnailUrl : noCover})`,
                                backgroundSize: cover ? 'cover' : '42px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                            }}
                            className="w-full h-[222px] relative top-0 border border-v2_purple rounded-[30px]"
                            onClick={() => setCoverModal(true)}
                        ></div>
                    </div>

                    {coverModal && (
                        <Images.Upload
                            title="Changer de photo de couverture"
                            setIsOpen={() => setCoverModal(false)}
                            isOpen={coverModal}
                            afterUpload={async (value) => {
                                const newPhotos = [...images.current]
                                newPhotos.push(value)

                                handleSetAttribute('cover', value)
                                setCoverModal(false)
                                await handleSaveAttribute('photos', newPhotos)
                                await handleSaveAttributeV2('cover', value)
                                await getImages()
                                setLoading(false)
                            }}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    )}
                </div>
            </Accordion.Profil>
            <Modal.Favorite
                title={''}
                isOpen={openHoraireToast}
                setIsOpen={() => {
                    setOpenHoraireToast(!openHoraireToast)
                    setToUpdate()
                }}
            >
                <div className="my-6">
                    <p className="text-[16px] font-semibold text-center">
                        Les horaires mis à jour seront disponibles sur votre profil dans les 5 minutes.
                    </p>
                    <div className="mt-6">
                        <Lottie
                            options={{
                                loop: true,
                                autoplay: true,
                                animationData: animationData,
                                rendererSettings: {
                                    preserveAspectRatio: 'xMidYMid slice',
                                },
                            }}
                            width={200}
                            height={200}
                        />
                    </div>
                </div>
            </Modal.Favorite>
        </div>
    )
}

const UpdateProfile = ({ categoryToUpdate }) => {
    const {
        firstName,
        lastName,
        phoneNumber,
        address,
        siret,
        rayon,
        category,
        yearsExperience,
        description,
        specialities,
        globalSpecialities,
        companyName,
        gender,
        avatar,
        birthday,
        personnalAddress,
        handleSaveAttribute,
        handleSetAttribute,
        createSpeciality,
        setToUpdate,
        handleSaveAttributeV2,
    } = ProContext.Profil.useProfilPro()
    const { defaultDays, setDefaultDays, saveDefaultDays } = ProContext.Schedule.useSchedule()
    const place = ProContext.Place.usePlace()
    const { images, getImages } = ImagesContext.useImages()
    const { setStep } = Address.useAddress()

    const [photoModal, setPhotoModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const [allSpecialities, setAllSpecialities] = useState(globalSpecialities)
    const [createSpecialities, setCreateSpecialities] = useState(false)
    const [newSpecialities, setNewSpecialities] = useState('')

    const [newCompanyName, setNewCompanyName] = useState(companyName || '')
    const [newFirstName, setNewFirstName] = useState(firstName || '')
    const [newLastName, setNewLastName] = useState(lastName || '')
    const [newPhoneNumber, setNewPhoneNumber] = useState(phoneNumber.phone || '')
    const [newSiret, setNewSiret] = useState(siret || '')
    const [newRayon, setNewRayon] = useState(rayon || '')
    const [tempCategories, setTempCategories] = useState(category || [])

    const [openHoraireToast, setOpenHoraireToast] = useState(false)

    useEffect(() => {
        setTempCategories(category || [])
    }, [category])

    const handleCategoryChange = (value) => {
        let updatedCategories = [...tempCategories]

        if (!value) {
            updatedCategories = []
        } else if (tempCategories.indexOf(value) === -1) {
            updatedCategories.push(value)
        } else {
            updatedCategories.splice(tempCategories.indexOf(value), 1)
        }

        setTempCategories(updatedCategories)
        handleSaveAttribute('category', updatedCategories)
    }

    const handleDayChange = (index, value) => {
        console.log('index', value)
        const daysCopy = [...defaultDays]
        daysCopy[index] = value
        setDefaultDays(daysCopy)
    }

    const [sexe, setSexe] = useState(() => [
        { name: 'Femme', value: gender === 'female' },
        { name: 'Homme', value: gender === 'male' },
        { name: 'Autre', value: gender === 'other' },
    ])

    const handleSexeChange = (index) => {
        const updatedSexe = sexe.map((item, i) => ({
            ...item,
            value: i === index,
        }))

        setSexe(updatedSexe)

        const genderMap = {
            Femme: 'female',
            Homme: 'male',
            Autre: 'other',
        }

        const selectedValue = updatedSexe.find((item) => item.value)
        const genderValue = genderMap[selectedValue.name]

        handleSaveAttributeV2('gender', genderValue)
    }

    useEffect(() => {
        const container = document.getElementById('scrollable-zone')
        console.log(container)
        container.scrollTo(0, 0)
    }, [categoryToUpdate])

    return (
        <>
            {categoryToUpdate === 'profile' ? (
                <>
                    <div className="grid gap-6 px-4">
                        <div className="flex justify-end w-full">
                            <div className="flex space-x-1" onClick={() => setToUpdate()}>
                                <span className="text-[12px] font-medium">Sauvegarder</span>
                                <Check color="black" size={20} />
                            </div>
                        </div>
                        <div>
                            <div
                                style={{
                                    backgroundImage: avatar ? `url(${avatar.thumbnailUrl})` : `url(${AvatarDefault})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center center',
                                }}
                                className="relative mx-auto size-16"
                                onClick={() => setPhotoModal(!photoModal)}
                            >
                                <div className="bg-[#fff] rounded-full absolute bottom-1 right-1 border border-black flex flex-row items-center justify-center size-4">
                                    <Plus size={24} color="#000" />
                                </div>
                            </div>
                        </div>
                        <Input.text
                            placeholder={"Nom de l'entreprise"}
                            value={newCompanyName}
                            onChange={(value) => setNewCompanyName(value)}
                            onBlur={(value) => handleSaveAttributeV2('companyName', value)}
                        />
                        <div className="flex flex-row items-center justify-between px-4">
                            {sexe &&
                                sexe.map((i, index) => (
                                    <Input.Checkbox
                                        label={i.name}
                                        value={i.value}
                                        key={index}
                                        setValue={() => handleSexeChange(index)}
                                    />
                                ))}
                        </div>
                        <div>
                            <p className="text-[16px] text-v2_purple font-semibold">Votre date de naissance</p>
                            <Input.Date
                                value={birthday}
                                setValue={(value) => {
                                    handleSetAttribute('birthday', dayjs(value).format('YYYY-MM-DD'))
                                    handleSaveAttributeV2('birthday', dayjs(value).format('YYYY-MM-DD'))
                                }}
                            />
                        </div>
                    </div>
                    {photoModal && (
                        <Images.Upload
                            title="Modifier la photo de profil"
                            setIsOpen={() => setPhotoModal(false)}
                            isOpen={photoModal}
                            afterUpload={async (value) => {
                                const newPhotos = [...images.current]
                                newPhotos.push(value)

                                handleSetAttribute('avatar', value)
                                await handleSaveAttribute('avatar', value)
                                await handleSaveAttribute('photos', newPhotos)
                                await getImages()
                                setPhotoModal(false)
                                setLoading(false)
                            }}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    )}
                </>
            ) : categoryToUpdate === 'apropos' ? (
                <div>
                    <div className="flex justify-end w-full">
                        <div className="flex space-x-1" onClick={() => setToUpdate()}>
                            <span className="text-[12px] font-medium">Sauvegarder</span>
                            <Check color="black" size={20} />
                        </div>
                    </div>
                    <div className="mb-4">
                        <Input.Dropdown
                            multiple={true}
                            value={tempCategories}
                            setValue={handleCategoryChange}
                            label={'Choisissez votre ou vos catégories'}
                            options={[
                                { value: 'coiffure', label: 'Coiffeur' },
                                { value: 'esthetique', label: 'Esthéticienne' },
                                { value: 'barbier', label: 'Barbier' },
                                { value: 'ongulaire', label: 'Prothésiste ongulaire' },
                            ]}
                        />
                    </div>
                    <div className="mb-4">
                        <Input.text
                            label={"Années d'expérience"}
                            placeholder={'10'}
                            value={yearsExperience}
                            onChange={(value) => {
                                let convertedValue = value ? parseInt(value) : 0

                                handleSetAttribute('yearsExperience', parseInt(convertedValue))
                            }}
                            onBlur={() => handleSaveAttribute('yearsExperience', yearsExperience)}
                        />
                    </div>
                    <div className="mb-4">
                        <Input.textarea
                            label={'Biographie'}
                            placeholder={'Présentez-vous de la manière que vous le souhaitez....'}
                            value={description}
                            onChange={(value) => handleSetAttribute('description', value)}
                            onBlur={() => handleSaveAttribute('description', description)}
                        />
                    </div>
                    <div className="my-4">
                        <Input.Specialities
                            data={allSpecialities}
                            label={'Ajouter vos spécialitées'}
                            placeholder={'Vos spécialitées'}
                            values={specialities}
                            onChange={(newValue) => {
                                let indexToRemove
                                let newSpecialities = [...specialities]
                                specialities.map((speciality, index) => {
                                    if (speciality._id === newValue._id) indexToRemove = index
                                })

                                if (typeof indexToRemove === 'number') {
                                    newSpecialities.splice(indexToRemove, 1)
                                    handleSaveAttribute('specialities', newSpecialities)
                                } else {
                                    newSpecialities.push(newValue)
                                    handleSaveAttribute('specialities', newSpecialities)
                                }
                                setAllSpecialities(allSpecialities.filter((item) => item._id !== newValue._id))
                            }}
                            reOrder={(value) => {
                                handleSaveAttribute('specialities', value)
                            }}
                        />
                        {createSpecialities ? (
                            <Input.text
                                placeholder={'Créer une spécialité personnalisé'}
                                value={newSpecialities}
                                onChange={(value) => setNewSpecialities(value)}
                                onBlur={() => {
                                    setCreateSpecialities(!createSpecialities)
                                    createSpeciality(newSpecialities)
                                    setNewSpecialities('')
                                }}
                                maxLength={30}
                            />
                        ) : (
                            <div onClick={() => setCreateSpecialities(!createSpecialities)}>
                                <Text.small color={'v2_purple'} className={'underline'}>
                                    Créer une spécialité personnalisé
                                </Text.small>
                            </div>
                        )}
                    </div>
                </div>
            ) : categoryToUpdate === 'horaires' ? (
                <div>
                    <div className="flex justify-between w-full px-[4px]">
                        <div className="flex space-x-1" onClick={() => setToUpdate()}>
                            <ArrowLeft size={20} />
                            <p className="text-[12px]">Retour</p>
                        </div>

                        <div
                            className="flex space-x-1"
                            onClick={() => {
                                saveDefaultDays()
                                setOpenHoraireToast(!openHoraireToast)
                                setToUpdate()
                            }}
                        >
                            <span className="text-[12px] font-medium">Sauvegarder</span>
                            <Check color="black" size={20} />
                        </div>
                    </div>
                    <ProTemplates.Address.Places from={'profil'}>
                        {defaultDays &&
                            defaultDays.map((day, index) => (
                                <div key={index} className="pb-2 my-4 px-2 border-b  border-[#979797]">
                                    <Horaires.TimingRDV day={day} onChange={(value) => handleDayChange(index, value)} />
                                </div>
                            ))}
                    </ProTemplates.Address.Places>
                </div>
            ) : categoryToUpdate === 'coords' ? (
                <div className="w-[95vw] mx-auto">
                    <div className="flex justify-end">
                        <div className="flex space-x-1" onClick={() => setToUpdate()}>
                            <span className="text-[12px] font-medium">Sauvegarder</span>
                            <Check color="black" size={20} />
                        </div>
                    </div>
                    <div className="grid gap-4 px-2">
                        <div className="w-[94vw]">
                            <p className="text-[16px] font-medium mb-1">Nom et Prénom</p>
                            <div className="grid grid-cols-2 grid-rows-1 gap-4">
                                <Input.text
                                    placeholder={'Nom'}
                                    value={newFirstName}
                                    onChange={(value) => setNewFirstName(value)}
                                    onBlur={(value) => handleSaveAttribute('lastName', value)}
                                    className="w-[35%]"
                                />
                                <Input.text
                                    placeholder={'Prénom'}
                                    value={newLastName}
                                    onChange={(value) => setNewLastName(value)}
                                    onBlur={(value) => handleSaveAttribute('firstName', value)}
                                    className="w-[35%]"
                                />
                            </div>
                        </div>

                        <div className="w-[94vw]">
                            <p className="text-[16px] font-medium mb-1">Téléphone</p>
                            <Input.phoneNumber
                                placeholder={'06 06 06 06 06'}
                                value={newPhoneNumber}
                                onChange={(value) => setNewPhoneNumber(value)}
                                handleSave={(value) =>
                                    handleSaveAttribute('phoneNumber', { code: 33, short: 'fr', phone: value })
                                }
                            />
                        </div>

                        <div className="w-[94vw]">
                            <ProTemplates.Address.Personnal>
                                <p className="text-[16px] font-medium mb-1">Adresse</p>
                                <div
                                    onClick={() => {
                                        place.setStep('hidden')
                                        setStep('address')
                                    }}
                                    className="py-[15px] px-[22px] border rounded-full bg-v2_white border-v2_purple text-nowrap overflow-hidden truncate text-[14px] font-medium leading-[19.6px] tracking-[-0.14px]"
                                >
                                    {personnalAddress?.formatted}
                                </div>
                            </ProTemplates.Address.Personnal>
                        </div>

                        <div className="w-[94vw]">
                            <p className="text-[16px] font-medium mb-1">Numero SIRET</p>
                            <Input.text
                                placeholder={'06723116888'}
                                value={newSiret}
                                keyboardType={'number'}
                                onChange={(value) => setNewSiret(value)}
                                onBlur={(value) => handleSaveAttribute('siret', value)}
                            />
                        </div>
                    </div>
                </div>
            ) : categoryToUpdate === 'deplacement' ? (
                <div className="grid gap-4 px-2">
                    <div>
                        <div className="flex justify-end w-full">
                            <div className="flex space-x-1" onClick={() => setToUpdate()}>
                                <span className="text-[12px] font-medium">Sauvegarder</span>
                                <Check color="black" size={20} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="text-[14px] font-semibold">Rayon de déplacement</p>
                        <div className="h-fit">
                            <ProTemplates.Address.default>
                                <Input.text
                                    value={address?.formatted}
                                    placeholder={'Entrez votre adresse'}
                                    onClick={() => {
                                        place.setStep('hidden')
                                        setStep('address')
                                    }}
                                />
                            </ProTemplates.Address.default>
                        </div>
                        <div className="flex flex-row items-center w-full my-4">
                            <div className="w-1/5">
                                <Input.text
                                    placeholder={'Entrez votre rayon de déplacement'}
                                    value={newRayon}
                                    onChange={(value) => setNewRayon(value)}
                                    onBlur={(value) => handleSaveAttribute('rayon', parseInt(value))}
                                    keyboardType="number"
                                />
                            </div>
                            <div className="ml-2">
                                <Text.normal className={'font-medium'} color={'v2_black'}>
                                    km
                                </Text.normal>
                            </div>
                        </div>
                    </div>
                    <div className="w-full h-fit">
                        {address?.formatted && rayon ? <Map.Rayon /> : <p>Chargement de la carte...</p>}
                    </div>
                    <div className="grid gap-[4px]">
                        <p className="text-[14px] leading-[21px] font-semibold">Lieux de travail</p>
                        <Caption.AtPlace />
                    </div>
                    {place?.list &&
                        place?.list.map((i, index) => {
                            return (
                                <div
                                    key={index}
                                    onClick={() => {
                                        place.setSelected(i)
                                        place.setStep('edit')
                                        setStep('address')
                                    }}
                                    className="py-[15px] px-[22px] border rounded-full bg-v2_white border-v2_purple text-nowrap overflow-hidden truncate text-[14px] font-medium leading-[19.6px] tracking-[-0.14px]"
                                >
                                    {i.name} - {i.address.formatted}
                                </div>
                            )
                        })}
                    <ProTemplates.Address.Places>
                        <div
                            className=" flex justify-between py-[12px] px-[22px] border rounded-full bg-v2_white border-v2_purple"
                            onClick={() => {
                                place.setStep('create')
                                setStep('address')
                            }}
                        >
                            <p className="text-[#979797]">Ajouter une adresse</p>
                            <Plus color="#72517E" size={20} />
                        </div>
                    </ProTemplates.Address.Places>
                </div>
            ) : null}
        </>
    )
}

const FooterBtns = ({ onChange }) => {
    const { handleLogout } = Auth.useAuth()

    return (
        <div className="grid w-full gap-[20px] px-[16px] py-[30px] mb-[30px] rounded-[30px]">
            <div className="grid gap-[11px]">
                <Button.primary title="Changer de mot de passe" onClick={() => onChange('changePwd')} />
                <Button.secondary
                    title="Déconnexion"
                    iconRight={<Icon.Logout className="w-[22px] h-[22px]" />}
                    onClick={() => {
                        handleLogout()
                    }}
                />
            </div>
            <div
                className="mx-auto text-[16px] tracking-[0px] leading-[20px] font-medium underline text-[#000]"
                onClick={() => onChange('deleteAccount')}
            >
                Supprimer mon compte
            </div>
        </div>
    )
}

const ChangePwd = ({ onChange }) => {
    const { handleChangePassword } = Auth.useAuth()
    const [oldPwd, setOldPwd] = useState('')
    const [newPwd, setNewPwd] = useState('')
    const [confirmNewPwd, setConfirmNewPwd] = useState('')

    return (
        <div>
            <div className="flex flex-row items-center p-2" onClick={() => onChange('')}>
                <ArrowLeft color="#72517E" size={25} />
                <p className="text-[16px] font-medium text-v2_purple">Retour</p>
            </div>
            <div className="grid gap-6 p-6">
                <Input.password
                    label={'Ancien mot de passe'}
                    placeholder={'Mot de passe'}
                    value={oldPwd}
                    onChange={(value) => setOldPwd(value)}
                />
                <Input.password
                    label={'Choisissez un mot de passe'}
                    placeholder={'Mot de passe'}
                    value={newPwd}
                    onChange={(value) => setNewPwd(value)}
                />
                <Input.password
                    label={'Confirmez le mot de passe'}
                    placeholder={'Mot de passe'}
                    value={confirmNewPwd}
                    onChange={(value) => setConfirmNewPwd(value)}
                />

                <Button.primary
                    title={'Sauvegarder'}
                    onClick={async () => {
                        await handleChangePassword(oldPwd, newPwd, confirmNewPwd)
                    }}
                />
            </div>
        </div>
    )
}

const DeleteAccount = ({ onChange }) => {
    const { deleteAccount } = Auth.useAuth()

    return (
        <div>
            <div className="flex flex-row items-center p-2" onClick={() => onChange('')}>
                <ArrowLeft color="#72517E" size={25} />
                <p className="text-[16px] font-medium text-v2_purple">Retour</p>
            </div>
            <div className="mt-[180px] px-4">
                <p className="text-center text-[20px] font-medium mb-8">
                    Êtes-vous sûr de vouloir supprimer votre compte ?
                </p>
                <Button.primary
                    title={'Confirmer'}
                    onClick={async () => {
                        await deleteAccount()
                    }}
                />
            </div>
        </div>
    )
}

export { ProfileCategories, UpdateProfile, FooterBtns, ChangePwd, DeleteAccount }
