/* eslint no-unused-vars: 0 */
import React, { createContext, useContext, useState, useEffect, useRef } from 'react'

const { socket } = require('../../../api/api')

import { useRequest } from '../../request'
import { useAuth } from '../../auth'

const ScheduleContext = createContext({
    dayEditing: Object,
    defaultDays: Array,
    setDefaultDays: (days) => {},
    saveDefaultDays: () => {},
    setDayEditing: (value) => {},
    saveDayEditing: async () => {
        return Promise.resolve
    },
})

export function ScheduleContextProvider({ children }) {
    const { handleRequest, setMessage } = useRequest()
    const { token, id, type, isLogged } = useAuth()

    const [dayEditing, setDayEditing] = useState()
    const [loading, setLoading] = useState(false)
    const [defaultDays, setDefaultDays] = useState([
        {
            name: 'lundi',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: true,
            blocs: [
                {
                    start: 9 * 3600,
                    end: 18 * 3600,
                    moving: true,
                    place: null,
                },
            ],
        },
        {
            name: 'mardi',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: true,
            blocs: [
                {
                    start: 9 * 3600,
                    end: 18 * 3600,
                    moving: true,
                    place: null,
                },
            ],
        },
        {
            name: 'mercredi',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: true,
            blocs: [
                {
                    start: 9 * 3600,
                    end: 18 * 3600,
                    moving: true,
                    place: null,
                },
            ],
        },
        {
            name: 'jeudi',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: true,
            blocs: [
                {
                    start: 9 * 3600,
                    end: 18 * 3600,
                    moving: true,
                    place: null,
                },
            ],
        },
        {
            name: 'vendredi',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: true,
            blocs: [
                {
                    start: 9 * 3600,
                    end: 18 * 3600,
                    moving: true,
                    place: null,
                },
            ],
        },
        {
            name: 'samedi',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: false,
            blocs: [],
        },
        {
            name: 'dimanche',
            startOfDay: 9 * 3600,
            endOfDay: 18 * 3600,
            isWorkingDay: false,
            blocs: [],
        },
    ])

    const saveDefaultDays = () => {
        setLoading(true)
        socket.emit('save_default_days', { proId: id, newDefaultDays: defaultDays })
    }

    const refreshCalendar = async () => {
        setLoading(false)
        if (id.toString() !== 'undefined') {
            const defaultDaysFetch = await handleRequest('get', `coiffeur/days/default/${id}`, null, token)
            if (defaultDaysFetch.data.days.length > 0) {
                let daysCopy = defaultDaysFetch.data.days.map((day) => {
                    const blocs =
                        day.blocs.length > 0
                            ? day.blocs
                            : day.startOfDay
                            ? [
                                  {
                                      start: day.startOfDay,
                                      end: day.endOfDay,
                                      moving: true,
                                      place: null,
                                  },
                              ]
                            : [
                                  {
                                      start: 9 * 3600,
                                      end: 18 * 3600,
                                      moving: true,
                                      place: null,
                                  },
                              ]

                    return {
                        ...day,
                        startOfDay: blocs ? blocs[0].start : day.startOfDay,
                        endOfDay: blocs ? blocs[blocs.length - 1].end : day.endOfDay,
                        blocs: blocs,
                    }
                })

                setDefaultDays(daysCopy)
            }
        }
    }

    useEffect(() => {
        if (isLogged() && type === 'coiffeur') {
            refreshCalendar()

            socket.on('refresh_default_days', () => {
                refreshCalendar()
                setMessage({
                    type: 'success',
                    message: "Ton calendrier viens d'être mis à jour avec tes nouveaux horaires.",
                })
                setTimeout(() => setMessage({ type: 'info', message: null }), 5000)
            })
        }
    }, [id, token])

    const saveDayEditing = async () => {
        if (dayEditing) {
            const response = await handleRequest('put', `coiffeur/days/${id}/${dayEditing.date}`, {
                startOfDay: dayEditing.startOfDay,
                endOfDay: dayEditing.endOfDay,
                blocs: dayEditing.blocs,
                isWorkingDay: dayEditing.isWorkingDay,
            }, token)

            if (response?.data) {
                return response.data.day
            }
        } else return
    }

    return (
        <ScheduleContext.Provider
            value={{
                defaultDays: defaultDays,
                loading: loading,
                dayEditing: dayEditing,
                setDayEditing: setDayEditing,
                setDefaultDays: setDefaultDays,
                saveDefaultDays: saveDefaultDays,
                saveDayEditing: saveDayEditing,
            }}
        >
            {children}
        </ScheduleContext.Provider>
    )
}

export const useSchedule = () => useContext(ScheduleContext)
