import React from 'react'
import { Text } from '../../../atoms'
import { Plus, Minus } from 'lucide-react'
import { ClientContext } from '../../../../contexts'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import { formatSecondsToHHhmm, formatMillisecondsToMinutes } from '../../../../utils'

export const Prestations = ({ prestations }) => {
    const { addPrestation, removePrestation } = ClientContext.Reservation.useReservation()

    const incrementCounter = (id) => {
        const prestation = prestations.find((p) => p._id === id)
        if (prestation) {
            addPrestation(prestation)
        }
    }

    const decrementCounter = (id) => {
        const prestation = prestations.find((p) => p._id === id)
        if (prestation) {
            removePrestation(id)
        }
    }

    return (
        <div className="flex flex-col py-6 space-y-4">
            <Text.normal className="px-4 font-semibold text-v2_purple">Prestations sélectionnées</Text.normal>
            {prestations.length > 0 && (
                <div className="p-4 space-y-4 shadow-basic">
                    {prestations.map((prestation) => (
                        <div key={prestation._id} className="flex justify-between w-full">
                            <Text.normal>{prestation.name}</Text.normal>
                            <div className="flex items-center">
                                <Minus
                                    className="mr-4 cursor-pointer"
                                    size={12}
                                    onClick={() => decrementCounter(prestation._id)}
                                />
                                <Text.normal>{prestation.counter}</Text.normal>
                                <Plus
                                    className="ml-4 cursor-pointer"
                                    size={12}
                                    onClick={() => incrementCounter(prestation._id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export const PrestationsCard = ({ prestations }) => {
    return (
        <div className="flex flex-col py-6 space-y-4">
            {prestations.length > 0 && (
                <div className="space-y-4 p-4 shadow-basic rounded-[30px]">
                    {prestations.map((prestation) => (
                        <div key={prestation._id} className="flex justify-between w-full">
                            <div key={prestation._id} className="flex items-center justify-between">
                                <div className="flex flex-col space-y-1">
                                    <Text.normal className="font-medium">{prestation.name}</Text.normal>
                                    <Text.small color="black">
                                        {prestation.price} € - {formatMillisecondsToMinutes(prestation.duration)} min
                                    </Text.small>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export const Date = ({ selectedReservation, goToStep }) => {
    const formatDate = () => {
        const formattedDate = dayjs().locale('fr').format('ddd D MMM')
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).replace('.', '')
    }

    return (
        <div className="flex flex-col py-8 space-y-4">
            <Text.normal className="px-4 font-semibold text-v2_purple">Choix de la date et de l'heure</Text.normal>
            <div className="p-4 space-y-4 shadow-basic" onClick={() => goToStep(2)}>
                <div className="flex justify-between w-full">
                    <Text.normal>{formatDate(selectedReservation.date)}</Text.normal>
                    <Text.normal>{formatSecondsToHHhmm(selectedReservation.horaire.start)}</Text.normal>
                </div>
            </div>
        </div>
    )
}
