import React, { useEffect, useState } from 'react'
import { Text, Button, Input, Modal, Loader } from '../../atoms'
import { Tab } from '../../molecules'
import { Profil } from '../../molecules/Client'
import { TbCalendarCheck, TbClock } from 'react-icons/tb'
import { IoCloseCircleOutline } from 'react-icons/io5'
import ppBoy from '../../../assets/avatar/Boy.svg'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import 'dayjs/locale/fr'

import { formatSecondsToHHhmm } from '../../../utils'
import { ClientContext } from '../../../contexts'
import { CalendarCheck, Clock, CreditCard, HandCoins, ChevronDown, ChevronUp, Pencil } from 'lucide-react'

export const Selecting = ({ bookings, initialProfil }) => {
    const [selectedTab, setSelectedTab] = useState(1)

    const handleTabClick = (tabIndex) => {
        setSelectedTab(tabIndex)
    }

    return (
        <div className="py">
            <Tab.CientProfil selectedTab={selectedTab} handleTabClick={handleTabClick} />
            {selectedTab === 1 && <Profil.RDV bookings={bookings} />}
            {selectedTab === 2 && <Profil.Coordonnees initialProfil={initialProfil} />}
        </div>
    )
}

export const BookingDetail = () => {
    const navigate = useNavigate()

    const { currentBooking, setStep } = ClientContext.Reservation.useReservation()

    const [clientNotes, setClientNotes] = useState([])
    const [expandedNotes, setExpandedNotes] = useState({})

    const State = () => {
        switch (currentBooking?.state) {
            case 'waiting':
                return 'En attente de confirmation'
            case 'cancel':
                if (currentBooking.canceledBy === 'client') return 'RDV Annulé par vous'
                else return 'RDV Annulé'
            case 'booked':
                return 'RDV confirmé'
            case 'done':
                return 'RDV passé'
            default:
                return ''
        }
    }

    const repeatRDV = () => {
        navigate(`/client/pro/prestations/${currentBooking._coiffeur?._id}`)
    }

    const toggleExpand = (id) => {
        setExpandedNotes((prev) => ({
            ...prev,
            [id]: !prev[id],
        }))
    }

    useEffect(() => {
        if (currentBooking) {
            setClientNotes(currentBooking.note.filter((i) => i.from === 'client'))
        }
    }, [currentBooking])

    return (
        <div className="flex flex-col items-center w-full space-y-[30px] pt-12 pb-4 px-[2%]">
            <div className="flex flex-col w-full space-y-8">
                <div className="grid gap-[30px]">
                    <div className="flex items-center justify-center mx-auto space-x-6 w-fit">
                        {currentBooking?.state === 'booked' && <TbCalendarCheck className={`w-8 h-8 text-v2_green`} />}
                        {currentBooking?.state === 'waiting' && <TbClock className={'w-8 h-8 text-v2_yellow'} />}
                        {currentBooking?.state === 'cancel' && (
                            <IoCloseCircleOutline className={'w-8 h-8 text-v2_red'} />
                        )}
                        {currentBooking?.state === 'done' && <TbCalendarCheck className={'w-8 h-8 text-v2_grey'} />}
                        <Text.h5 className="font-semibold" color={'black'}>
                            <State />
                        </Text.h5>
                    </div>
                    <div className="grid gap-[24px] w-full">
                        <div className="flex items-center justify-center w-full space-x-8">
                            <div className="flex rounded-full size-20 ">
                                {currentBooking._coiffeur?._profil?.avatar?.thumbnailUrl ? (
                                    <img
                                        src={currentBooking._coiffeur?._profil?.avatar?.thumbnailUrl}
                                        alt="Photo de profil du Client"
                                    />
                                ) : (
                                    <img src={ppBoy} alt="Photo de profil par défault du Client" />
                                )}
                            </div>
                            <div className="flex flex-col h-full">
                                <Text.normal className="text-[20px] font-semibold">
                                    {currentBooking._coiffeur?._profil?.firstName}
                                </Text.normal>
                                <Text.normal className="text-[20px] font-semibold">
                                    {currentBooking._coiffeur?._profil?.lastName}
                                </Text.normal>
                            </div>
                        </div>
                        <div className="w-full grid grid-cols-2 bg-white rounded-full shadow-basic py-[6px] px-[8px]">
                            <div
                                className={`flex items-center justify-end pr-[13px] h-[58px] border-r ${
                                    currentBooking?.state === 'cancel'
                                        ? 'border-[#979797]'
                                        : currentBooking?.state === 'done'
                                        ? 'border-[#979797]'
                                        : 'border-[#1c1c1c]'
                                }`}
                            >
                                <CalendarCheck
                                    size={24}
                                    color={
                                        currentBooking?.state === 'cancel'
                                            ? '#979797'
                                            : currentBooking?.state === 'done'
                                            ? '#979797'
                                            : '#1c1c1c'
                                    }
                                />
                                <p
                                    className={`font-medium text-[18px] ${
                                        currentBooking?.state === 'cancel'
                                            ? 'text-v2_grey'
                                            : currentBooking?.state === 'done'
                                            ? 'text-v2_grey'
                                            : 'text-black'
                                    }`}
                                >
                                    {dayjs(currentBooking?.date).locale('fr').format('D MMM YYYY')}
                                </p>
                            </div>
                            <div className="flex items-center justify-start pl-[13px] h-[58px]">
                                <Clock
                                    size={24}
                                    color={
                                        currentBooking?.state === 'cancel'
                                            ? '#979797'
                                            : currentBooking?.state === 'done'
                                            ? '#979797'
                                            : '#1c1c1c'
                                    }
                                />
                                <p
                                    className={`font-medium text-[18px] ${
                                        currentBooking?.state === 'cancel'
                                            ? 'text-v2_grey'
                                            : currentBooking?.state === 'done'
                                            ? 'text-v2_grey'
                                            : 'text-black'
                                    }`}
                                >
                                    {formatSecondsToHHhmm(currentBooking?.from)} -{' '}
                                    {formatSecondsToHHhmm(currentBooking?.to)}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-col w-full space-y-[16px]">
                            <Text.normal className="flex font-semibold text-v2_purple uppercase text-[16px] leading-[24px]">
                                Prestations
                            </Text.normal>
                            <div className="flex flex-col ml-10">
                                <ul className="list-disc">
                                    {currentBooking?._prestation.map((item, index) => (
                                        <li key={index}>
                                            <p
                                                className={`font-medium leading-5 ${
                                                    currentBooking?.state === 'cancel'
                                                        ? 'text-v2_grey'
                                                        : currentBooking?.state === 'done'
                                                        ? 'text-v2_grey'
                                                        : 'text-black'
                                                }`}
                                            >
                                                {item.name}
                                            </p>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="flex flex-col w-full space-y-[16px]">
                            <Text.normal className="flex font-semibold text-v2_purple">
                                Modes de paiement acceptés
                            </Text.normal>
                            <div className="flex items-center space-x-2">
                                <HandCoins size={24} />
                                <Text.normal className="font-medium">Espèces</Text.normal>
                            </div>

                            <div className="flex items-center space-x-2">
                                <CreditCard size={24} />
                                <Text.normal className="font-medium">Carte bancaire</Text.normal>
                            </div>
                        </div>
                        <div className="flex flex-col w-full text-center">
                            <Text.h5
                                color={
                                    currentBooking?.state === 'cancel' || currentBooking?.state === 'done'
                                        ? 'v2_grey'
                                        : 'black'
                                }
                                className="font-[500] text-[18px] text-center"
                            >
                                Le coût total : {currentBooking.priceVariable ? 'À partir de ' : ''}
                                {currentBooking?.price} €
                            </Text.h5>
                        </div>
                    </div>
                </div>
            </div>

            {clientNotes && clientNotes.length > 0 ? (
                <div className="grid gap-[16px] mt-[17px] ml-[18px]">
                    <p className="text-[16px] font-semibold text-v2_purple uppercase">Note pour le profesionnel</p>
                    {clientNotes.map((i) => {
                        const isExpanded = expandedNotes[i._id]

                        return (
                            <div
                                key={i._id}
                                className="grid gap-[10px] bg-white shadow-basic pb-[28px] pt-[21px] rounded-t-[30px]"
                            >
                                <div className="flex flex-row items-center justify-between">
                                    <div className="flex flex-row items-center">
                                        {currentBooking?._client?._profil?.avatar ? (
                                            <img
                                                className="size-6 mr-2"
                                                src={currentBooking?._client?._profil?.avatar?.thumbnailUrl}
                                                alt="Photo de profil du Client"
                                            />
                                        ) : (
                                            <img
                                                className="size-6 mr-2"
                                                src={ppBoy}
                                                alt="Photo de profil par défault du Client"
                                            />
                                        )}
                                        <p className="text-[16px] leading-[20px] font-semibold">Moi:</p>
                                    </div>
                                    <Pencil size={20} color="#000" />
                                </div>
                                <p className="text-[14px] leading-[20px]">
                                    {isExpanded ? i.value : `${i.value.slice(0, 50)}...`}
                                </p>
                                <div className="flex flex-row items-center" onClick={() => toggleExpand(i._id)}>
                                    <p className="text-[14px] leading-[20px] cursor-pointer text-v2_purple underline font-semibold mr-2">
                                        {isExpanded ? 'Voir moins' : 'Voir plus'}
                                    </p>
                                    {isExpanded ? (
                                        <ChevronUp size={20} color="#72517e" />
                                    ) : (
                                        <ChevronDown size={20} color="#72517e" />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                </div>
            ) : null}

            <div className="flex flex-col items-center w-full space-y-4">
                {currentBooking?.state === 'booked' && (
                    <Button.primary title={'Annuler le RDV'} onClick={() => setStep('cancel')} />
                )}
                {currentBooking?.state === 'waiting' && (
                    <Button.primary title={'Annuler le RDV'} onClick={() => setStep('cancel')} />
                )}

                {currentBooking?.state === 'cancel' && (
                    <>
                        <Button.secondary
                            title={'Choisir une autre date'}
                            onClick={() => navigate(`/client/pro/prestations/${currentBooking._coiffeur?._id}`)}
                        />
                    </>
                )}
                {currentBooking?.state === 'done' && (
                    <Button.secondary title={'Répéter RDV'} onClick={() => repeatRDV()} />
                )}
            </div>
        </div>
    )
}

export const Comment = () => {
    const { leaveComment } = ClientContext.Reservation.useReservation()

    const [comment, setComment] = useState('')
    const [isSend, setIsSend] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSend = async () => {
        setLoading(true)
        await leaveComment(comment)
        setLoading(false)
        setIsSend(true)
    }

    return (
        <div>
            {isSend && <Modal.Comment closeModal={() => setIsSend(!isSend)} />}
            {!loading ? (
                <div className="flex flex-col px-4 py-8 space-y-10">
                    <Text.h4 className="font-medium text-center">Commentaire sur le RDV</Text.h4>
                    <Input.textarea
                        value={comment}
                        onChange={(value) => {
                            setComment(value)
                        }}
                        placeholder={'Votre commentaire'}
                    />
                    <Button.primary onClick={() => handleSend()} title={'Envoyer'} />
                </div>
            ) : (
                <div className="absolute top-0 left-0 grid items-center w-full h-full px-4 py-6 bg-white">
                    <div className="flex flex-col space-y-2 h-fit">
                        <Loader.Primary />
                        <Text.h4 className="text-center" color="v2_purple"></Text.h4>
                    </div>
                </div>
            )}
        </div>
    )
}
