import React, { useState } from 'react'
import { Input, Text } from '../../atoms'
import { Address } from '../../../contexts'

import { ArrowLeft, MapPin } from 'lucide-react'

const Export = ({ launchResearch, noHome }) => {
    const { selectedAddress, addressSuggestions, getLatLong, step, setStep, searchAddress } = Address.useAddress()

    const [research, setResearch] = useState(false)
    const [searchValue, setSearchValue] = useState(selectedAddress)

    const handleSearchAddress = async (value) => {
        await searchAddress(value)
    }

    return (
        <>
            <div className="h-[100vh] bg-white z-[99] fixed top-0 w-[100vw] left-0">
                <div className="bg-white border-b border-[#D4D4D4] flex px-[24px] py-2 items-center">
                    <div
                        onClick={() => {
                            setStep(step === 'address' ? (!noHome ? 'home' : 'results') : 'address')
                        }}
                    >
                        <ArrowLeft size={24} color="#72517E" />
                    </div>
                    <div className="mr-auto ml-auto text-[20px] font-[500]">
                        {!research ? <p>Addresse</p> : <p>Rechercher</p>}
                    </div>
                </div>
                <div className="shadow-modal px-[22px]">
                    <Input.searchAddress
                        value={searchValue}
                        placeholder={'Entrez votre adresse (N°, rue, ville...)'}
                        onChange={(value) => {
                            setSearchValue(value.toString())
                            handleSearchAddress(value.toString())
                        }}
                        onSearch={() => {
                            setResearch(true)
                        }}
                    />
                </div>
                {addressSuggestions && addressSuggestions.length > 0 ? (
                    <div className="bg-white shadow-modal h-logged overflow-auto">
                        {addressSuggestions.map((i, index) => {
                            return (
                                <div key={index}>
                                    {i ? (
                                        <div>
                                            <div
                                                className="flex flex-row items-center w-full px-4 py-2 border-b-[0.5px] border-b-black"
                                                key={index}
                                                onClick={async (e) => {
                                                    setSearchValue(e.target.innerText)
                                                    const { lat, lon, formatted } = await getLatLong(
                                                        i.autocompleteId,
                                                        i.sessionId,
                                                        i
                                                    )
                                                    launchResearch(formatted, lat, lon)
                                                }}
                                            >
                                                <div className="mr-4">
                                                    <MapPin color="#000" size={24} />
                                                </div>
                                                <div>
                                                    <Text.h5 className="font-[500]" color={'#000'}>
                                                        {i.full_address}
                                                    </Text.h5>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            )
                        })}
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default Export
